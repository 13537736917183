<template>
    <div class="panelsEditBox">
        <!--校验外部验证码弹框-->
        <el-dialog :title="$t('RevalidateDevice')" 
        :visible.sync="updatePanels.addRevalidateFrom" 
        :close-on-click-modal="false" 
        :close-on-press-escape="false"
        center 
        >
            <el-form>
                <el-form-item :label="$t('PanelsSerialNumber')">
                    <el-input :disabled="true" v-model="updatePanels.entranceDeviceId"></el-input>
                </el-form-item>
                <el-form-item :label="$t('PanelsSecurityCode')">
                    <el-input v-model="updatePanels.securityCode"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button  @click="updatePanels.addRevalidateFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" style="background: #6498b5;color: #fff;font-weight: bold;" :loading="updatePanels.addRevalidateBtnState === 1" @click="saveUpdatePanelsSecurityCode">{{$t('Confirm')}}</el-button>
            </div>
        </el-dialog>
        <!--删除Sip Setting 弹框-->
       <el-dialog
            :title="$t('Delete')"
            :visible.sync="del.delPanelsFrom"
            :close-on-click-modal="false" 
            :close-on-press-escape="false"
            width="30%"
            :show-close="del.showClose">
            <p class="deleteDesc">{{$t('ConfirmDelSip')}}</p>
            <span slot="footer" class="dialog-footer addGuard">
                <el-button @click="del.delPanelsFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" class="selectBtn" style="margin-left: 60px !important" :loading="del.delBtnState === 1" @click="delPanelsSip">{{$t('Confirm')}}</el-button>
            </span>
        </el-dialog>
        <!-- 新增Guard弹框 -->
        <el-dialog
            :title="guardAdd.titleTex"
            :visible.sync="guardAdd.addGuardFrom"
            :close-on-click-modal="false" 
            :close-on-press-escape="false"
            width="30%"
            :show-close="guardAdd.showClose">
            <div class="addGuardBox">
                <div>
                    <el-form>
                        <el-form-item :label="$t('GuardName') + ' *' ">
                            <el-input v-model="guardAdd.guardName" :placeholder="$t('GuardName')"></el-input>
                        </el-form-item>
                        <div class="NameBox">
                            <div style="width:48%">
                                <el-form-item :label="$t('Sites') + ' ' + $t('Or') + ' ' + $t('Blocks') + ' *' ">
                                    <el-select @change="guardSelAparment" class="apartmentSel" :disabled='guardAdd.model === 1' v-model="guardAdd.apartmentId" :placeholder="$t('Sites') + '/' + $t('Blocks')">
                                        <el-option
                                        v-for="item in guardAdd.apartmentOptions"
                                        :key="item.apartmentId"
                                        :label="item.apartmentId === '0' ? $t('Sites') : item.apartmentName"
                                        :value="item.apartmentId">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div style="width:48%">
                                <!-- <el-form-item label="Address">
                                    <el-input v-model="guardAdd.userHouseId"></el-input>
                                </el-form-item> -->
                                <el-form-item :label="$t('Address') + ' *'">
                                    <el-select class="apartmentSel" :disabled='guardAdd.model === 1' v-model="guardAdd.userHouseId" :placeholder="$t('Address')">
                                        <el-option
                                        v-for="item in guardAdd.userHouseIdOptions"
                                        :key="item.userHouseId"
                                        :label="item.userName"
                                        :value="item.userHouseId">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        
                    </el-form>
                    <div slot="footer" class="dialog-footer addGuard">
                        <el-button @click="guardAdd.addGuardFrom = false">{{$t('Cancel')}}</el-button>
                        <el-button type="primary" :loading="guardAdd.addBtnState === 1" @click="addGuardSave">{{$t('Confirm')}}</el-button>
                    </div>
                </div>
            
            </div>
        </el-dialog>
         <!--删除Guard 弹框-->
       <el-dialog
            :title="$t('Delete')"
            :visible.sync="guardDel.delGuardFrom"
            :close-on-click-modal="false" 
            :close-on-press-escape="false"
            width="30%"
            :show-close="guardDel.showClose">
            <p class="deleteDesc">{{$t('ConfirmDelGuard')}}</p>
            <span slot="footer" class="dialog-footer addGuard">
                <el-button @click="guardDel.delGuardFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" class="selectBtn" style="margin-left: 60px !important" :loading="guardDel.delBtnState === 1" @click="delPanelsguardDel">{{$t('Confirm')}}</el-button>
            </span>
        </el-dialog>
      <div class="controlBtnBox">
        <div>
            <el-button :class="{selectBtn:selectBtnIndex===1}" @click="changeEditType(1)">{{$t('PanelsDetail')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===2}" @click="changeEditType(2)">{{$t('AccessControl')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===3}" @click="changeEditType(3)">{{$t('SipSettings')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===4}" @click="changeEditType(4)">{{$t('DTMFSetting')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===5}" @click="changeEditType(5)">{{$t('GuardSettings')}}</el-button>
            <el-button :class="{selectBtn:selectBtnIndex===6}" @click="changeEditType(6)">{{$t('CCTVSettings')}}</el-button>
        </div>
        <div>
            <el-button v-show="sip.showAddSipBtn === 1" class="selectBtn" @click="addSipServer">{{$t('AddSipServer')}}</el-button>
            <el-button v-show="guard.showAddGuardBtn === 1" class="selectBtn" @click="addGuardSetting">{{$t('CreateGuard')}}</el-button>
            <el-button v-show="selectBtnIndex === 6" class="selectBtn" @click="addCCTVSetting">{{$t('Add')}}</el-button>
        </div>
        
      </div>
      <!-- Panels Detail编辑 -->
      <div class="panelsContent" v-show="selectBtnIndex === 1">
        <div class="panelsBox">
            <div class="panelsBoxLeft">
                <div class="panelsItem">
                    <label>{{$t('PanelsName')}}</label><el-input v-model="updatePanels.name" class="editInput" :placeholder="$t('PanelsName')"></el-input>
                </div>
                <div class="panelsItem">
                    <label>{{$t('Sites') + " " + $t('Or') + " " + $t('Blocks')}}</label>
                    <el-select v-model="updatePanels.apartmentId" class="editInput" :placeholder="$t('Sites') + ' ' + $t('Or') + ' ' + $t('Blocks')">
                        <el-option
                        v-for="item in updatePanels.apartmentOptions"
                        :key="item.id"
                        :label="item.apartmentName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    <!-- <el-input v-model="updatePanels.number" class="editInput" placeholder="Blocks Name"></el-input> -->
                </div>
                <div class="panelsItem" style="display:none;">
                    <label>{{$t('Blocks')}}</label>
                    <el-select v-model="updatePanels.blocksId" disabled class="editBlock" :placeholder="$t('Blocks')">
                        <el-option
                        v-for="item in updatePanels.blocksOptions"
                        :key="item.id"
                        :label="item.apartmentName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="panelsItem">
                    <div>{{$t('PanelsStatus')}}</div>
                    <div class="panelsItemR"
                    :class="{
                                statusGreen:getPanelsStatusClass(updatePanels.deviceStatus) === 'statusGreen',
                                statusOrange:getPanelsStatusClass(updatePanels.deviceStatus) === 'statusOrange',
                                statusRed:getPanelsStatusClass(updatePanels.deviceStatus) === 'statusRed',
                                statusBlue:getPanelsStatusClass(updatePanels.deviceStatus) === 'statusBlue'
                            }"
                    >{{getPanelsStatusStr(updatePanels.deviceStatus)}}</div>
                </div>
            </div>
            <div class="panelsBoxRight">
                <div class="panelsRightItem">
                    <div class="panelsItemL"> {{$t('DevicesMode')}} </div><div class="panelsItemR">{{updatePanels.deviceModel}}</div>
                </div>
                <div class="panelsRightItem">
                    <div class="panelsItemL"> {{$t('SerialNumber')}} </div><div class="panelsItemR">{{updatePanels.entranceDeviceId}}</div>
                </div>
                <div class="panelsRightItem">
                    <div class="panelsItemL"> {{$t('DeviceVersion')}} </div><div class="panelsItemR">{{updatePanels.deviceVersion}}</div>
                </div>
            </div>
        </div>
        <div class="bottomDiv">
            <el-button class="savetBtn" :disabled="updatePanels.deviceStatus != 3" :loading="this.updatePanels.revalidateBtnState == 1" @click="saveRevalidate">{{$t('Revalidate')}}</el-button>
            <el-button class="savetBtn" :disabled="updatePanels.saveBtn" :loading="this.updatePanels.updateBtnState == 1" @click="saveUpdatePanels">{{$t('Save')}}</el-button>
        </div>
        
      </div>
      <!-- Panels Access Control编辑 -->
      <div class="panelsContent" v-show="selectBtnIndex === 2">
        <div class="panelspermission">
            <div class="permissionEditDiv">
                <div class="permissionTex"><el-divider direction="vertical"></el-divider> <span>{{$t('RolePermission')}}</span></div>
                <div class="permissionItem" v-for="(item,key) in updatePanels.permissionList" :key="key">
                    <div class="permissionEditLeft" v-if="key === 'user'">{{$t('SiteUser')}}</div>
                    <div class="permissionEditLeft" v-else-if="key === 'employee'">{{$t('SiteEmployee')}}</div>
                    <div class="permissionEditLeft" v-else-if="key === 'others'">{{$t('SiteOther')}}</div>
                    <div class="permissionRight" >
                        <img :src="updatePanels.preview" v-if="item.indexOf('1')>-1" @click="removePermission(item,key,'1')" alt="">
                        <img :src="updatePanels.unpreview" v-else @click="selectPermission(item,key,'1')" alt="">
                        <img :src="updatePanels.lock" v-if="item.indexOf('2')>-1" @click="removePermission(item,key,'2')" alt="">
                        <img :src="updatePanels.unlock" v-else @click="selectPermission(item,key,'2')" alt="">
                        <img :src="updatePanels.call" v-if="item.indexOf('3')>-1" @click="removePermission(item,key,'3')" alt="">
                        <img :src="updatePanels.uncall" v-else @click="selectPermission(item,key,'3')" alt="">
                    </div>
                </div>
            </div>
            <div class="bottomDiv">
                <el-button class="savetBtn" :disabled="updatePanels.savePermiBtn" :loading="this.updatePanels.updatePermBtnState == 1" @click="saveUpdatePanelsPermission">{{$t('Save')}}</el-button>
            </div>
        </div>
        
      </div>
      <!-- Sip Settings -->
      <div class="panelsContent" v-show="selectBtnIndex === 3">
        <div v-show="sip.model === 0">
            <div class="noSipsettings" v-show="sip.hasNosip">{{$t('NoSip')}}!</div>
            <template>
                <el-table
                v-show="!sip.hasNosip"
                :data="sip.sipList"
                style="width: 100%;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    label="#"
                    type="index"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="title"
                    :label="$t('ServerName')"
                    width="150"
                    >
                </el-table-column>
                
                <el-table-column
                    prop="usedStatus"
                    :label="$t('Status')"
                    width="150"
                    >
                    <template slot-scope="scope">
                            {{scope.row.usedStatus === 0 ? $t("Unused") : $t("InUse")}}
                    </template>
                </el-table-column>
                <el-table-column width="150"  label="..." align="center">
                    <template slot-scope="scope">
                        <el-image :src="sip.editImg"  @click="editlSip(scope.row)" style="width:24px;" fit="fill"></el-image>
                        <el-image :src="sip.deleteImg"  @click="openDelSipFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                    </template>
                </el-table-column>

                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div class="pageBox">
                <el-pagination
                    v-show="!sip.hasNosip"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="sip.currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="sip.totalPanels"
                >
                </el-pagination>
            </div>
        </div>
        <div v-show="sip.model === 1">
            <div class="addSipDiv">
                <div class="addSipLeft">
                    <h4>{{$t('AccountSettings')}}</h4>
                    <div class="sipItem">
                        <label class="addStar">{{$t('DisplayName')}}</label>
                        <el-input v-model="sipAdd.name" class="editInput" :placeholder="$t('DisplayName')"></el-input>
                    </div>
                    <div class="sipItem">
                        <label class="addStar">{{$t('Remark')}}</label>
                        <el-input v-model="sipAdd.remark" class="editInput" :placeholder="$t('Remark')"></el-input>
                    </div>
                    <div class="sipItem">
                        <label class="addStar">{{$t('Server')}}</label>
                        <el-input v-model="sipAdd.serverIp" class="editInput" :placeholder="$t('Server')"></el-input>
                    </div>
                    <div class="sipItem">
                        <label class="addStar">{{$t('Port')}}</label>
                        <el-input v-model="sipAdd.serverPort" class="editInput" :placeholder="$t('Port')"></el-input>
                    </div>
                    <div class="sipItem">
                        <label class="addStar">{{$t('TCPOrUDP')}}</label>
                        <el-select v-model="sipAdd.serverProtocol" class="editInput" :placeholder="$t('TCPOrUDP')">
                            <el-option
                            v-for="item in sipAdd.serverProtocolOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="sipItem">
                        <label class="addStar">{{$t('Account')}}</label>
                        <el-input v-model="sipAdd.account" class="editInput" :placeholder="$t('Account')"></el-input>
                    </div>
                    <div class="sipItem">
                        <label class="addStar">{{$t('PassWord')}}</label>
                        <form>
                            <el-input v-model="sipAdd.password" :type="sipAdd.passwordFlage" class="editInput" :placeholder="$t('PassWord')" auto-complete="new-password">
                                <i slot="suffix" class="el-input__icon" @click="changePasswordShow">
                                    <img style="width: 20px;margin-top: 10px; margin-right: 10px;" :src="sipAdd.passwordImg" alt="">
                                </i>
                            </el-input>
                        </form>
                        
                    </div>
                </div>
                <!-- <div class="addSipRight">
                    <h4>{{$t('DTMFSetting')}}</h4>
                    <div class="sipItem">
                        <label class="addStar">{{$t('Lock1')}}</label>
                        <el-input v-model="sipAdd.dtmfLock1" class="editInput" :placeholder="$t('Lock1')"></el-input>
                    </div>
                    <div class="sipItem">
                        <label class="addStar">{{$t('Lock2')}}</label>
                        <el-input v-model="sipAdd.dtmfLock2" class="editInput" :placeholder="$t('Lock2')"></el-input>
                    </div>
                    <div class="sipItem">
                        <label class="addStar">{{$t('Talk')}}</label>
                        <el-input v-model="sipAdd.dtmfTalk" class="editInput" :placeholder="$t('Talk')"></el-input>
                    </div>
                </div> -->
            </div>
            <div class="bottomDiv">
                <el-button class="savetBtn" :loading="this.sipAdd.addSipBtnState == 1" @click="saveAddSip">{{$t('Save')}}</el-button>
            </div>
        </div>
      </div>
      <!-- DTMF Settings -->
      <div class="panelsContent" v-show="selectBtnIndex === 4">
        <div>
            <div class="addSipDiv">
                <div class="addSipLeft">
                    <h4>{{$t('DTMFSetting')}}</h4>
                    <div class="sipItem">
                        <!-- <label class="addStar">{{$t('Lock1')}}</label> -->
                        <!-- <el-input v-model="dtmf.lock1" class="editInput" :placeholder="$t('Lock1')"></el-input> -->
                        <label class="addStar">{{$t('Lock1')}}</label>
                        <el-select v-model="dtmf.lock1" @change="dtmfLock1Sel" class="editInput" :placeholder="$t('Lock1')">
                            <el-option
                            v-for="item in dtmf.lock1Options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.val">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="sipItem">
                        <!-- <label class="addStar">{{$t('Lock2')}}</label>
                        <el-input v-model="dtmf.lock2" class="editInput" :placeholder="$t('Lock2')"></el-input> -->
                        <label class="addStar">{{$t('Lock2')}}</label>
                        <el-select v-model="dtmf.lock2" @change="dtmfLock2Sel" class="editInput" :placeholder="$t('Lock2')">
                            <el-option
                            v-for="item in dtmf.lock2Options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.val">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="sipItem">
                        <!-- <label class="addStar">{{$t('Talk')}}</label>
                        <el-input v-model="dtmf.talk" class="editInput" :placeholder="$t('Talk')"></el-input> -->
                        <label class="addStar">{{$t('Talk')}}</label>
                        <el-select v-model="dtmf.talk" @change="dtmfTalkSel" class="editInput" :placeholder="$t('Talk')">
                            <el-option
                            v-for="item in dtmf.talkOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.val">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="bottomDiv">
                <el-button class="savetBtn" :loading="this.dtmf.dtmfBtnState == 1" @click="saveDtmf">{{$t('Save')}}</el-button>
            </div>
        </div>
      </div>
      <!-- Guard Settings -->
      <div class="panelsContent" v-show="selectBtnIndex === 5">
        <div v-show="sip.model === 0">
            <div class="noSipsettings" v-show="guard.hasNoguard">{{$t('NoGuard')}}!</div>
            <template>
                <el-table
                v-show="!guard.hasNoguard"
                :data="guard.guardList"
                style="width: 100%;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    label="#"
                    type="index"
                    width="100"
                    >
                </el-table-column>
                <el-table-column
                    prop="guardName"
                    :label="$t('GuardName')"
                    width="200"
                    >
                </el-table-column>
                
                <el-table-column
                    prop="userApartmentName"
                    :label="$t('Blocks') + '/'+ $t('Sites')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{scope.row.userApartmentId === "0" ? $t('Sites') : scope.row.userApartmentName}}
                    </template>
                </el-table-column>
                <el-table-column width="200"  label="..." align="center">
                    <template slot-scope="scope">
                        <el-image :src="guard.editImg"  @click="editlGuard(scope.row)" style="width:24px;" fit="fill"></el-image>
                        <el-image :src="guard.deleteImg"  @click="openDelGuardFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                    </template>
                </el-table-column>

                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div class="pageBox">
                <el-pagination
                    v-show="!guard.hasNoguard"
                    @size-change="handleSizeChangeGuard"
                    @current-change="handleCurrentChangeGuard"
                    :current-page.sync="guard.currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="sip.totalPanels"
                >
                </el-pagination>
            </div>
        </div>
      </div>
       <!-- CCTV Settings -->
      <div class="panelsContent" v-show="selectBtnIndex === 6">
        <!-- 新增/编辑CCTV Settings弹框 -->
        <el-dialog
            :title="cctvAdd.titleTex"
            :visible.sync="cctvAdd.cctvGuardFrom"
            :close-on-click-modal="false" 
            :close-on-press-escape="false"
            width="30%"
            :show-close="cctvAdd.showClose">
            <div>
                <el-form>
                    <el-form-item :label="$t('Name') + ' *'">
                        <el-input v-model="cctvAdd.title" :placeholder="$t('Name')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('IPRTSPUrl') + ' *'">
                        <el-input v-model="cctvAdd.serverUrl" :placeholder="$t('IPRTSPUrl')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('UserName') + ' *'">
                        <el-input v-model="cctvAdd.username" :placeholder="$t('UserName')" auto-complete="off"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('PassWord') + ' *'">
                        <el-input v-model="cctvAdd.password" :type="cctvAdd.passwordFlage" :placeholder="$t('PassWord')" auto-complete="new-password">
                                <i slot="suffix" class="el-input__icon" @click="changeCCTVPasswordShow">
                                    <img style="width: 20px;margin-top: 15px; margin-right: 10px;" :src="cctvAdd.passwordImg" alt="">
                                </i>
                            </el-input>
                        <!-- <el-input v-model="cctvAdd.serverUrl" :placeholder="$t('PassWord')"></el-input> -->
                    </el-form-item>
                    <el-form-item :label="$t('Stream') + ' *'">
                        <el-select  v-model="cctvAdd.stream" :placeholder="$t('Stream')" class="apartmentSel" style="width: 100%;">
                            <el-option
                                v-for="item in cctvAdd.streamOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    
                </el-form>
                <div slot="footer" class="dialog-footer addGuard">
                    <el-button @click="cctvAdd.cctvGuardFrom = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" :loading="cctvAdd.cctvBtnState === 1" @click="addOrEditCCTVSave">{{$t('Confirm')}}</el-button>
                </div>
            
            </div>
        </el-dialog>
        <!--删除CCTV 弹框-->
        <el-dialog
            :title="$t('Delete')"
            :visible.sync="cctvDel.delCCTVFrom"
            :close-on-click-modal="false" 
            :close-on-press-escape="false"
            width="30%"
            :show-close="cctvDel.showClose">
            <p class="deleteDesc">{{$t('DeletingCCTV')}}</p>
            <span slot="footer" class="dialog-footer addGuard">
                <el-button @click="cctvDel.delCCTVFrom = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" class="selectBtn" style="margin-left: 60px !important" :loading="cctvDel.delBtnState === 1" @click="delPanelsCCTV">{{$t('Confirm')}}</el-button>
            </span>
        </el-dialog>
        <div class="noSipsettings" v-show="cctv.hasNocctv">{{$t('NoData')}}!</div>
        <template>
            <el-table
            v-show="!cctv.hasNocctv"
            :data="cctv.cctvList"
            style="width: 100%;"
            :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
            >
            <el-table-column
                label="#"
                type="index"
                width="100"
                >
            </el-table-column>
            <el-table-column
                prop="title"
                :label="$t('Name')"
                width="200"
                >
            </el-table-column>
            <el-table-column width="200"  label="..." align="center">
                <template slot-scope="scope">
                    <el-image :src="cctv.editImg"  @click="editlCCTV(scope.row)" style="width:24px;" fit="fill"></el-image>
                    <el-image :src="cctv.deleteImg"  @click="openDelCCTVFrom(scope.row)" style="width:24px;margin-left:1vw" fit="fill"></el-image>
                </template>
            </el-table-column>

            <el-table-column>

            </el-table-column>
        
            </el-table>
        </template>
        <div class="pageBox">
            <el-pagination
                v-show="!cctv.hasNocctv"
                @size-change="handleSizeChangeCCTV"
                @current-change="handleCurrentChangeCCTV"
                :current-page.sync="cctv.currentPage"
                :page-size="10"
                layout="prev, pager, next, jumper"
                :total="cctv.totalcctvs"
            >
            </el-pagination>
        </div>
      </div>

    </div>
  
</template>
<script>
import {nanoid} from 'nanoid'
import { mapState, mapMutations } from 'vuex';
import { sha256 } from 'js-sha256'
import commonFun from '@/common/index.js'
import lock1 from '@/assets/images/lock1.png'
import lock2 from '@/assets/images/lock2.png'
import call1 from '@/assets/images/call1.png'
import call2 from '@/assets/images/call2.png'
import preview1 from '@/assets/images/preview1.png'
import preview2 from '@/assets/images/preview2.png'
import editImg from '@/assets/images/edit.png'
import deleteImg from '@/assets/images/delete.png'
import showPassword from '@/assets/images/password_show.png'
import hidePassword from '@/assets/images/password_hide.png'
import sitesPanelsApi from '@/api/panels/index.js'
export default {
    name:"PanelsEdit",
    data(){
        return{
            selectBtnIndex:1,
            updatePanels:{
                id:"",
                entranceDeviceId:"",
                name:"",
                newName:"",
                apartmentId:"",
                newApartmentId:"",
                apartmentOptions:[],
                blocksId:"",
                blocksOptions:[],
                deviceModel:"",
                deviceVersion:"",
                deviceStatus:"",
                lock:lock1,
                unlock:lock2,
                call:call1,
                uncall:call2,
                preview:preview1,
                unpreview:preview2,
                permissionList:{
                    user:[],
                    employee:[],
                    others:[],
                },
                oldPermissionList:{
                    user:[],
                    employee:[],
                    others:[],
                },
                saveBtn:true,
                savePermiBtn:true,
                revalidateBtnState:0,
                updateBtnState:0,
                updatePermBtnState:0,
                securityCode:"",
                addRevalidateFrom:false,
                addRevalidateBtnState:0,
            },
            sip:{
                model:0,
                hasNosip:false,
                sipList:[],
                editImg,
                deleteImg,
                showAddSipBtn:0,
                totalPanels:0,
                currentPage: 1,
            },
            sipAdd:{
                passwordFlage:"password",
                passwordImg:hidePassword,
                showPassword,
                hidePassword,
                name:"",
                remark:"",
                serverIp:"",
                serverPort:"",
                serverProtocol:"",
                serverProtocolOptions:[
                    {
                        id:"tcp",
                        name:"TCP"
                    },
                    {
                        id:"udp",
                        name:"UDP"
                    },
                ],
                account:"",
                password:"",
                addSipBtnState:0,
                isAddSip:true,
                entranceConfigId:"",
            },
            del:{
                showClose:true,
                delPanelsFrom:false,
                entranceConfigId:"",
                delBtnState:0
            },
            dtmf:{
                entranceConfigId:"",
                lock1:"",
                lock1Options:[],
                lock2:"",
                lock2Options:[],
                talk:"",
                talkOptions:[],
                dtmfBtnState:"",
                dtmfOptions:[],
            },
            guard:{
                model:0,
                hasNoguard:false,
                guardList:[],
                editImg,
                deleteImg,
                showAddGuardBtn:0,
                totalPanels:0,
                currentPage: 1,
            },
            guardAdd:{
                titleTex:this.$t('CreateGuard'),
                model:0, //0:添加，1:编辑
                addGuardFrom:false,
                showClose:true,
                addBtnState:0,
                entranceGuardId:"",
                guardName:"",
                apartmentId:"",
                apartmentOptions:[],
                userHouseId:"",
                userHouseIdOptions:[],
                userHouseIdOptionsAll:[],
            },
            guardDel:{
                showClose:true,
                delGuardFrom:false,
                entranceGuardId:"",
                delBtnState:0
            },
            cctv:{
                hasNocctv:false,
                cctvList:[],
                editImg,
                deleteImg,
                totalcctvs:0,
                currentPage: 1,
            },
            cctvAdd:{
                titleTex:"Add Camera",
                isAddCCTV:true,
                cctvGuardFrom:false,
                showClose:true,
                passwordFlage:"password",
                passwordImg:hidePassword,
                showPassword,
                hidePassword,
                cctvBtnState:0,
                entranceConfigId:"",
                title:"",
                serverUrl:"",
                username:"",
                password:"",
                stream:"",
                streamOptions:[
                    {
                        id:"1",
                        name:this.$t('MainStream')
                    },
                    {
                        id:"2",
                        name:this.$t('SubStream')
                    },
                ],
            },
            cctvDel:{
                showClose:true,
                delCCTVFrom:false,
                entranceCCTVId:"",
                delBtnState:0
            },
        }
    },
    mounted(){
        // this.$store.commit("CHANGEMODEL","company");
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/panels',
                name:this.$t('Panels')
            },
            {
                id:nanoid(),
                path:'/main/panels',
                name:this.$t('PanelsDetail')
            }
        ];
        this.$generateBreadcrumb(siteMangeBread);
        //获取Block列表，渲染下拉列表框
        this.getBlocksListForSelect();
    },
    methods:{
            // ...mapMutations(["OPENINEDITPAGE"]),
           //获取Block列表，渲染下拉列表框
           getBlocksListForSelect(){
            let blocksInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
            }
            sitesPanelsApi.getSiteBlocksListForSelect(blocksInfoObj).then(res => {
                if(res.code === 0){
                    this.updatePanels.apartmentOptions = [];
                    this.updatePanels.apartmentOptions.unshift({
                        "id": "0",
                        "apartmentName": this.$t('Sites'),
                    });
                    for(let i=0;i< res.data.length;i++){
                        this.updatePanels.apartmentOptions.push(res.data[i]);
                    }
                    //获取楼栋列表之后，再获取详情，渲染楼栋选择框
                    this.updatePanels.id = this.$route.params.id;
                    this.detailPanels({entranceDeviceId:this.updatePanels.id});
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
         //panel状态渲染函数
         getPanelsStatusClass(obj){
            let getClass = "";
                let status = obj;
                switch(status){
                    case 0:
                        getClass = "statusBlue";
                        break;
                    case 1:
                        getClass = "statusGreen";
                        break
                    case 2:
                        getClass = "statusOrange";
                        break
                    case 3:
                        getClass = "statusRed";
                        break
                    default:
                        getClass = "";
                }
                return getClass;
        },
        //panels状态转换函数
        getPanelsStatusStr(obj){
            let getStr = "";
            switch(obj){
                case 0:
                    getStr = this.$t('Offine');
                    break;
                case 1:
                    getStr = this.$t('Ready');
                    break
                case 2:
                    getStr = this.$t('NeedConfigration');
                    break
                case 3:
                    getStr = this.$t('NeedVerification');
                    break
                default:
                    getStr = "";
                }
                return getStr;
        },
        //获取Panel详情
        detailPanels(item){
            // console.log("detail",item);
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
                "entranceDeviceId":item.entranceDeviceId
            };
             // 刷新时，apartmentid丢失，这里做个规避
             if(this.updatePanels.id === undefined){
                return false
            }
            sitesPanelsApi.detailSitePanels(rgnObj).then(res => {
                if(res.code === 0){
                   this.updatePanels.name = res.data.deviceName;
                   this.updatePanels.newName = res.data.deviceName;
                   this.updatePanels.entranceDeviceId = res.data.deviceId;
                   this.updatePanels.apartmentId = res.data.apartmentId;
                   this.updatePanels.newApartmentId = res.data.apartmentId;
                   this.updatePanels.deviceModel = res.data.productId;
                   this.updatePanels.deviceVersion = res.data.deviceVersion;
                   this.updatePanels.deviceStatus = res.data.deviceStatus;
                   for(let i in res.data.permissionMap){
                        //清空老的数据
                        this.updatePanels.permissionList[i].splice(0, (this.updatePanels.permissionList[i]).length);
                        this.updatePanels.oldPermissionList[i].splice(0, (this.updatePanels.oldPermissionList[i]).length);
                        for(let j in res.data.permissionMap[i]){
                            this.updatePanels.permissionList[i].push(res.data.permissionMap[i][j]);
                            this.updatePanels.oldPermissionList[i].push(res.data.permissionMap[i][j]);
                        }
                        this.updatePanels.permissionList[i].sort();
                        this.updatePanels.oldPermissionList[i].sort();
                   }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        changeEditType(type){
            this.selectBtnIndex = type;
            if(type === 1){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('Panels')
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('PanelsDetail')
                    }
                ];
                this.sip.showAddSipBtn = 0;
                this.guard.showAddGuardBtn = 0;
                this.$generateBreadcrumb(siteMangeBread);
            }else if(type === 2){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('Panels')
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('AccessControl')
                    }
                ];
                this.sip.showAddSipBtn = 0;
                this.guard.showAddGuardBtn = 0;
                this.$generateBreadcrumb(siteMangeBread);
            }else if(type === 3){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('Panels')
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('SipSettings')
                    }
                ];
                this.sip.showAddSipBtn = 1;
                this.guard.showAddGuardBtn = 0;
                this.sip.model = 0;
                this.$generateBreadcrumb(siteMangeBread);
                //获取sip 配置列表
                this.getEntranceConfig(1);
            }else if(type === 4){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('Panels')
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('DTMFSetting')
                    }
                ];
                this.sip.showAddSipBtn = 0;
                this.guard.showAddGuardBtn = 0;
                this.$generateBreadcrumb(siteMangeBread);
                //获取dtmf配置
                this.getEntranceDTMFConfig(1);
            }else if(type === 5){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('Panels')
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('GuardSettings')
                    }
                ];
                this.sip.showAddSipBtn = 0;
                this.guard.showAddGuardBtn = 1;
                this.$generateBreadcrumb(siteMangeBread);
                //获取Guard Setting配置
                this.getEntranceGuardConfig(1);
                //获取指定设备下 Blocks , user列表，渲染site/Block下拉列表框,Address下拉列表框
                this.getBlocksUserListForSelect();
            }else if(type === 6){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('Panels')
                    },
                    {
                        id:nanoid(),
                        path:'/main/panels',
                        name:this.$t('CCTVSettings')
                    }
                ];
                this.sip.showAddSipBtn = 0;
                this.guard.showAddGuardBtn = 0;
                this.$generateBreadcrumb(siteMangeBread);
                //获取CCTV Setting配置
                this.getEntranceCCTVConfig(1);
            }
        },
       
        //更新设备（panels）基本信息 guard.showAddGuardBtn
        saveUpdatePanels(){
            if((this.updatePanels.name).trim() === ""){
                this.$message.info(this.$t('PanelsNameEmpty'));
                return false
            }
            let checkResultDeviceName = this.$regularCheckLimit(this.updatePanels.name,1,30,'DeviceNameLimit');
            if(!checkResultDeviceName){
                return false
            }
            if((this.updatePanels.apartmentId).trim() === ""){
                this.$message.info(this.$t('SiteOrBlocksEmpty'));
                return false
            }
            this.updatePanels.updateBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let updatePanelsObj = {
                "id":this.updatePanels.id,
                "siteId":this.selectSite.id,
                "apartmentId":this.updatePanels.apartmentId,
                "deviceName":this.updatePanels.name,
            }
            // console.log("updatePanelsObj",updatePanelsObj);
            // return false
            sitesPanelsApi.updateSitePanels(rgnObj,updatePanelsObj).then(res => {
                if(res.code === 0){
                    this.updatePanels.updateBtnState = 0;
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.updatePanels.saveBtn = true;
                    this.updatePanels.newName = this.updatePanels.name;
                    this.updatePanels.newApartmentId = this.updatePanels.apartmentId;
                }else{
                    this.updatePanels.updateBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.updatePanels.updateBtnState = 0;
            })
            // this.updatePanels.updateBtnState = 0;
        },
        //新的校验码弹框
        saveRevalidate(){
            this.updatePanels.addRevalidateFrom = true;
            this.updatePanels.addRevalidateBtnState = 0;
        },
        //保存新的验证码
        saveUpdatePanelsSecurityCode(){
            if((this.updatePanels.entranceDeviceId).trim() === ""){
                this.$message.info(this.$t('PanelsSerialNumberEmpty'));
                return false
            }
            let checkResultDeviceId = this.$regularCheckLimit(this.updatePanels.entranceDeviceId,1,20,'DeviceSerialLimit');
            if(!checkResultDeviceId){
                return false
            }
            if((this.updatePanels.securityCode).trim() === ""){
                this.$message.info(this.$t('PanelsSecurityNumberEmpty'));
                return false
            }
            let checkResultSecurityCode = this.$regularCheckLimit(this.updatePanels.securityCode,1,64,'DeviceSecurityCodeLimit');
            if(!checkResultSecurityCode){
                return false
            }
            this.updatePanels.addRevalidateBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let updatesecurityObj = {
                "siteId":this.selectSite.id,
                "deviceId":this.updatePanels.entranceDeviceId,
                "securityCode":sha256(this.updatePanels.securityCode),
            }
            // console.log("updatePanelsObj",updatePanelsObj);
            // return false
            sitesPanelsApi.updatePanelsSecurityCode(rgnObj,updatesecurityObj).then(res => {
                if(res.code === 0){
                    this.updatePanels.addRevalidateBtnState = 0;
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.detailPanels({entranceDeviceId:this.updatePanels.id});
                    this.updatePanels.addRevalidateFrom = false;
                }else{
                    // this.$message.success(this.$t('UpdateFailure'));
                    this.updatePanels.addRevalidateBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.$message.success(this.$t('UpdateFailure'));
                this.updatePanels.addRevalidateBtnState = 0;
            })
        },
        //处理权限
        selectPermission(item,key,val){
            this.updatePanels.permissionList[key].push(val);
            this.updatePanels.permissionList[key].sort();
        },
        removePermission(item,key,val){
            commonFun.removeByValue(this.updatePanels.permissionList[key],val)
            // console.log(this.updatePanels.permissionList[key]);
            this.updatePanels.permissionList[key].sort();
        },
         //更新设备（panels）权限信息
         saveUpdatePanelsPermission(){
           
            this.updatePanels.updatePermBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let updatePanelsPermissionObj = {
                "id":this.updatePanels.id,
                "siteId":this.selectSite.id,
                "permissionMap":this.updatePanels.permissionList,
            }
            // console.log("updatePanelsPermissionObj",updatePanelsPermissionObj);
            // return false
            sitesPanelsApi.updateSitePanelsPermission(rgnObj,updatePanelsPermissionObj).then(res => {
                if(res.code === 0){
                    this.updatePanels.updatePermBtnState = 0;
                    this.$message.success(this.$t('UpdateSuccessfully'));
                    this.updatePanels.savePermiBtn = true;
                    for(let i in this.updatePanels.permissionList){
                        //清空老的数据
                        this.updatePanels.oldPermissionList[i].splice(0, (this.updatePanels.oldPermissionList[i]).length);
                        for(let j in this.updatePanels.permissionList[i]){
                            this.updatePanels.oldPermissionList[i].push(this.updatePanels.permissionList[i][j]);
                        }
                        this.updatePanels.oldPermissionList[i].sort();
                   }
                    
                }else{
                    this.updatePanels.updatePermBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.updatePanels.updatePermBtnState = 0;
            })
            
        },
        
        /**
         * Sip Settings
         */

        //获取设备sip配置
        getEntranceConfig(pageNum){
            let panelsSipObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "entranceDeviceId":this.updatePanels.id,
                "type":'sip',
                "pageSize":10,
                "pageNum":pageNum,
            }
            sitesPanelsApi.getSitePanelsConfigList(panelsSipObj).then(res => {
                if(res.code === 0){
                    this.sip.sipList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.sip.hasNosip = true;
                    }else{
                        this.sip.hasNosip = false;
                        this.sip.sipList = res.data.list;
                    }
                    this.sip.currentPage = res.data.pageNum;
                    this.sip.totalPanels = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //sip setting 点击编辑执行函数editlGuard
        editlSip(item){
            this.sip.showAddSipBtn = 0;
            this.sip.model = 1;
            this.sipAdd.isAddSip = false;
            this.sipAdd.entranceConfigId = item.entranceConfigId;
            let sipServerObj = JSON.parse(item.payload);
            this.sipAdd.name = sipServerObj.name;
            this.sipAdd.remark = sipServerObj.remark;
            this.sipAdd.serverIp = sipServerObj.serverIp;
            this.sipAdd.serverPort = sipServerObj.serverPort;
            this.sipAdd.serverProtocol = sipServerObj.serverProtocol;
            this.sipAdd.account = sipServerObj.account;
            this.sipAdd.password = sipServerObj.password;
            this.sipAdd.passwordImg = this.sipAdd.hidePassword;
            this.sipAdd.passwordFlage = "password";
        },
        //点击显示隐藏密码执行函数
        changePasswordShow(){
            if(this.sipAdd.passwordImg === this.sipAdd.showPassword){
                this.sipAdd.passwordImg = this.sipAdd.hidePassword;
                this.sipAdd.passwordFlage = "password";
            }else{
                this.sipAdd.passwordImg = this.sipAdd.showPassword;
                this.sipAdd.passwordFlage = "text";
            }
        },
        //点击添加 Sit Setting Server 按钮
        addSipServer(){
            this.sipAdd.isAddSip = true;
            this.sip.showAddSipBtn = 0;
            this.sip.model = 1;
            this.sipAdd.name="";
            this.sipAdd.remark="";
            this.sipAdd.serverIp="";
            this.sipAdd.serverPort="";
            this.sipAdd.serverProtocol="";
            this.sipAdd.account="";
            this.sipAdd.password="";
            this.sipAdd.passwordImg = this.sipAdd.hidePassword;
            this.sipAdd.passwordFlage = "password";
        },
        //保存Sit Setting 
        saveAddSip(){
            let checkSipName = this.$regularCheckLimit(this.sipAdd.name,1,64,'DisplayNameEmpty');
            if(!checkSipName){
                return false
            }
            let checkSipRemark = this.$regularCheckLimit(this.sipAdd.remark,1,64,'RemarkEmpty');
            if(!checkSipRemark){
                return false
            }
            let checkSipServerIp = this.$regularCheckLimit(this.sipAdd.serverIp,1,64,'ServerEmpty');
            if(!checkSipServerIp){
                return false
            }
            let checkSipServerPort = this.$regularCheckLimit(this.sipAdd.serverPort,1,20,'PortEmpty');
            if(!checkSipServerPort){
                return false
            }
            if((this.sipAdd.serverProtocol).trim() === ""){
                this.$message.info(this.$t('TCPOrUDPEmpty'));
                return false
            }
            let checkSipAccount = this.$regularCheckLimit(this.sipAdd.account,1,64,'AccountEmpty');
            if(!checkSipAccount){
                return false
            }
            let checkSipPasswordt = this.$regularCheckLimit(this.sipAdd.password,1,64,'PasswordLimit');
            if(!checkSipPasswordt){
                return false
            }
                      
            this.sipAdd.addSipBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let addPanelsSipObj = {
                "type": "sip",
                "entranceDeviceId": this.updatePanels.id,
                "title": this.sipAdd.name,
            };
            addPanelsSipObj.payload = JSON.stringify({
                "name":this.sipAdd.name,
                "remark":this.sipAdd.remark,
                "serverPort":this.sipAdd.serverPort,
                "serverIp":this.sipAdd.serverIp,
                "serverProtocol":this.sipAdd.serverProtocol,
                "account":this.sipAdd.account,
                "password":this.sipAdd.password,
            })
            if(this.sipAdd.isAddSip){
                sitesPanelsApi.addSitePanelsConfig(rgnObj,addPanelsSipObj).then(res => {
                    if(res.code === 0){
                        this.$message.success(this.$t('AddSuccessful'));
                        this.sipAdd.addSipBtnState = 0;
                        this.sip.showAddSipBtn = 1;
                        this.sip.model = 0;
                        //获取sip 配置列表
                        this.getEntranceConfig(1);
                    }else{
                        this.sipAdd.addSipBtnState = 0;
                        this.sip.showAddSipBtn  = 0;
                        this.$handerrcode(res.code);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.sipAdd.addSipBtnState = 0;
                    this.sip.showAddSipBtn  = 0;
                })
            }else{
                addPanelsSipObj.entranceConfigId = this.sipAdd.entranceConfigId;
                sitesPanelsApi.updateSitePanelsConfig(rgnObj,addPanelsSipObj).then(res => {
                    if(res.code === 0){
                        this.$message.success(this.$t('UpdateSuccessfully'));
                        this.sipAdd.addSipBtnState = 0;
                        this.sip.showAddSipBtn = 1;
                        this.sip.model = 0;
                        //获取sip 配置列表
                        this.getEntranceConfig(1);
                    }else{
                        this.sipAdd.addSipBtnState = 0;
                        this.sip.showAddSipBtn  = 0;
                        this.$handerrcode(res.code);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.sipAdd.addSipBtnState = 0;
                    this.sip.showAddSipBtn  = 0;
                })
            }
            
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.getEntranceConfig(val);
        },
        //点击sip删除图标执行
        openDelSipFrom(item){
            this.del.delPanelsFrom = true;
            this.del.entranceConfigId = item.entranceConfigId;
        },
        //删除SiP Setting
        delPanelsSip(){
            //删除
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const delPanelsSipObj = {}
            delPanelsSipObj.entranceConfigId = this.del.entranceConfigId;
            this.del.delBtnState = 1;
            sitesPanelsApi.delSitePanelsSipConfig(rgnObj,delPanelsSipObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.del.delPanelsFrom = false
                    this.del.delBtnState = 0;
                   //获取sip 配置列表
                   this.getEntranceConfig(1);
                }else{
                    this.$handerrcode(res.code);
                    this.del.delBtnState = 0;
                }
                
            }).catch((err) => {
                console.log('err', err);
                this.del.delBtnState = 0;
            })
        },
        //获取DTMF Setting
        getEntranceDTMFConfig(pageNum){
            let panelsSipObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "entranceDeviceId":this.updatePanels.id,
                "type":'dtmf',
                "pageSize":10,
                "pageNum":pageNum,
            }
            sitesPanelsApi.getSitePanelsConfigList(panelsSipObj).then(res => {
                if(res.code === 0){
                    this.sip.sipList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.dtmf.entranceConfigId = "";
                        // this.dtmf.lock1 = "";
                        // this.dtmf.lock2 = "";
                        // this.dtmf.talk = "";
                        this.dtmf.lock1 = "*";
                        this.dtmf.lock2 = "#";
                        this.dtmf.talk = "0";
                        // //动态生成dtmf相关选项
                        let dtmfArr = ["*","#","0","1","2","3","4","5","6","7","8","9"];
                        this.dtmf.dtmfOptions = [];
                        dtmfArr.map(item=>{
                            this.dtmf.dtmfOptions.push(
                                {
                                    id:nanoid(),
                                    val:item,
                                    name:item
                                }
                            )
                        });
                        this.dtmf.lock1Options = this.dtmf.dtmfOptions.filter(item=>{
                            return item.val != this.dtmf.lock2 && item.val != this.dtmf.talk
                        })
                        this.dtmf.lock2Options = this.dtmf.dtmfOptions.filter(item=>{
                            return item.val != this.dtmf.lock1 && item.val != this.dtmf.talk
                        })
                        this.dtmf.talkOptions = this.dtmf.dtmfOptions.filter(item=>{
                            return item.val != this.dtmf.lock1 && item.val != this.dtmf.lock2
                        })
                    }else{
                        //动态生成dtmf相关选项
                        let dtmfArr = ["*","#","0","1","2","3","4","5","6","7","8","9"];
                        this.dtmf.dtmfOptions = [];
                        dtmfArr.map(item=>{
                            this.dtmf.dtmfOptions.push(
                                {
                                    id:nanoid(),
                                    val:item,
                                    name:item
                                }
                            )
                        });
                        let dtmfObj = JSON.parse(res.data.list[0].payload);
                        this.dtmf.entranceConfigId = res.data.list[0].entranceConfigId;
                        this.dtmf.lock1 = dtmfObj.lock1;
                        this.dtmf.lock2 = dtmfObj.lock2;
                        this.dtmf.talk = dtmfObj.talk;
                        this.dtmf.lock1Options = this.dtmf.dtmfOptions.filter(item=>{
                            return item.val != this.dtmf.lock2 && item.val != this.dtmf.talk
                        })
                        this.dtmf.lock2Options = this.dtmf.dtmfOptions.filter(item=>{
                            return item.val != this.dtmf.lock1 && item.val != this.dtmf.talk
                        })
                        this.dtmf.talkOptions = this.dtmf.dtmfOptions.filter(item=>{
                            return item.val != this.dtmf.lock1 && item.val != this.dtmf.lock2
                        })

                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //dtmf lock1下拉列表框改变执行函数
        dtmfLock1Sel(){
            this.dtmf.lock2Options = this.dtmf.dtmfOptions.filter(item=>{
                return item.val != this.dtmf.lock1 && item.val != this.dtmf.talk
            })
            this.dtmf.talkOptions = this.dtmf.dtmfOptions.filter(item=>{
                return item.val != this.dtmf.lock1 && item.val != this.dtmf.lock2
            })
        },
        //dtmf lock2下拉列表框改变执行函数
        dtmfLock2Sel(){
            this.dtmf.lock1Options = this.dtmf.dtmfOptions.filter(item=>{
                return item.val != this.dtmf.lock2 && item.val != this.dtmf.talk
            })
            this.dtmf.talkOptions = this.dtmf.dtmfOptions.filter(item=>{
                return item.val != this.dtmf.lock1 && item.val != this.dtmf.lock2
            })
        },
        //dtmf ltalk下拉列表框改变执行函数
        dtmfTalkSel(){
            this.dtmf.lock1Options = this.dtmf.dtmfOptions.filter(item=>{
                return item.val != this.dtmf.lock2 && item.val != this.dtmf.talk
            })
            this.dtmf.lock2Options = this.dtmf.dtmfOptions.filter(item=>{
                return item.val != this.dtmf.lock1 && item.val != this.dtmf.talk
            })
        },
        //保存DTMF Setting 
        saveDtmf(){
            // let checkDtmfLock1 = this.$regularCheckLimit(this.dtmf.lock1,1,20,'Lock1Empty');
            // if(!checkDtmfLock1){
            //     return false
            // }
            // let checkDtmfLock2 = this.$regularCheckLimit(this.dtmf.lock2,1,20,'Lock2Empty');
            // if(!checkDtmfLock2){
            //     return false
            // }
            // let checkDtmftalk = this.$regularCheckLimit(this.dtmf.talk,1,20,'TalkEmpty');
            // if(!checkDtmftalk){
            //     return false
            // }
           
            this.dtmf.dtmfBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let addPanelsDtmfObj = {
                "type": "dtmf",
                "entranceDeviceId": this.updatePanels.id,
                "title": "dtmf",
            };
            if(this.dtmf.entranceConfigId){
                addPanelsDtmfObj.entranceConfigId = this.dtmf.entranceConfigId
            }
            addPanelsDtmfObj.payload = JSON.stringify({
                "lock1":this.dtmf.lock1,
                "lock2":this.dtmf.lock2,
                "talk":this.dtmf.talk,
            })
            if(!this.dtmf.entranceConfigId){
                sitesPanelsApi.addSitePanelsConfig(rgnObj,addPanelsDtmfObj).then(res => {
                    if(res.code === 0){
                        this.$message.success(this.$t('AddSuccessful'));
                        this.dtmf.dtmfBtnState = 0;
                        //获取dtmf 配置列表
                        this.getEntranceDTMFConfig(1);
                    }else{
                        this.dtmf.dtmfBtnState = 0;
                        this.$handerrcode(res.code);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.dtmf.dtmfBtnState = 0;
                })
            }else{
                sitesPanelsApi.updateSitePanelsConfig(rgnObj,addPanelsDtmfObj).then(res => {
                    if(res.code === 0){
                        this.$message.success(this.$t('UpdateSuccessfully'));
                        this.dtmf.dtmfBtnState = 0;
                        //获取dtmf 配置列表
                        this.getEntranceDTMFConfig(1);
                    }else{
                        this.dtmf.dtmfBtnState = 0;
                        this.$handerrcode(res.code);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.dtmf.dtmfBtnState = 0;
                })
            }
            
        },

        //获取Guard Setting
        getEntranceGuardConfig(pageNum){
            let panelsGuardObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "entranceDeviceId":this.updatePanels.id,
                "type":'guard',
                "pageSize":10,
                "pageNum":pageNum,
            }
            sitesPanelsApi.getSitePanelsGuardConfigList(panelsGuardObj).then(res => {
                if(res.code === 0){
                    this.guard.guardList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.guard.hasNoguard = true;
                       
                    }else{
                        this.guard.hasNoguard = false;
                        this.guard.guardList = res.data.list;
                    }
                    this.guard.currentPage = res.data.pageNum;
                    this.guard.totalPanels = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //Guard 分页操作
        handleSizeChangeGuard(val) {
            // console.log(`每页 ${val} 条`);
        },
        //Guard 分页操作
        handleCurrentChangeGuard(val) {
            this.getEntranceGuardConfig(val);
        },
        //获取指定设备下 Blocks , user列表，渲染site/Block下拉列表框,Address下拉列表框
         getBlocksUserListForSelect(){
            let blocksUserInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "entranceDeviceId":this.updatePanels.id,
            }
            sitesPanelsApi.getSiteBlocksUserListForSelect(blocksUserInfoObj).then(res => {
                if(res.code === 0){
                    this.guardAdd.apartmentOptions = [];
                    this.guardAdd.userHouseIdOptions = [];
                    this.guardAdd.userHouseIdOptionsAll = [];
                    res.data.apartmentInfo.map(item=>{
                        this.guardAdd.apartmentOptions.push(item)
                    });
                    res.data.guardUserInfo.map(item=>{
                        this.guardAdd.userHouseIdOptions.push(item)
                        this.guardAdd.userHouseIdOptionsAll.push(item)
                    })
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //点击添加 Create New Guard  按钮
        addGuardSetting(){
            this.guardAdd.addGuardFrom = true;
            this.guardAdd.model = 0;
            this.guardAdd.titleTex = this.$t('CreateGuard');
            this.guardAdd.guardName = "";
            this.guardAdd.apartmentId = "";
            this.guardAdd.userHouseId = "";
        },
        //apartment选择框改变执行函数
        guardSelAparment(val){
            this.guardAdd.userHouseIdOptions = [];
            this.guardAdd.userHouseIdOptionsAll.map((item)=>{
                if(item.apartmentId === val){
                    this.guardAdd.userHouseIdOptions.push(item)
                }
            })
        },
        //新增Guard 点击保存
        addGuardSave(){
            let checkGuardNamek = this.$regularCheckLimit(this.guardAdd.guardName,1,20,'GuardNameLimit');
            if(!checkGuardNamek){
                return false
            }
            if((this.guardAdd.apartmentId).trim() === ""){
                this.$message.info(this.$t('SiteOrBlocksEmpty'));
                return false
            }
            if((this.guardAdd.userHouseId).trim() === ""){
                this.$message.info(this.$t('AddressEmpty'));
                return false
            }
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let addPanelsGuardObj = {
                "entranceDeviceId": this.updatePanels.id,
                "userApartmentId": this.guardAdd.apartmentId,
                "userHouseId": this.guardAdd.userHouseId,
                "guardName": this.guardAdd.guardName,
            };
            if(this.guardAdd.model){
                addPanelsGuardObj.entranceGuardId = this.guardAdd.entranceGuardId;
            }
            this.guardAdd.addBtnState = 1;
            if(!this.guardAdd.model){
                sitesPanelsApi.addSitePanelsGuard(rgnObj,addPanelsGuardObj).then(res => {
                    if(res.code === 0){
                        this.$message.success(this.$t('AddSuccessful'));
                        this.guardAdd.addBtnState = 0;
                        this.guardAdd.addGuardFrom = false;
                        //获取dtmf 配置列表
                        this.getEntranceGuardConfig(1);
                    }else{
                        this.guardAdd.addBtnState = 0;
                        this.$handerrcode(res.code);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.guardAdd.addBtnState = 0;
                })
            }else{
                sitesPanelsApi.updateSitePanelsGuard(rgnObj,addPanelsGuardObj).then(res => {
                    if(res.code === 0){
                        this.$message.success(this.$t('UpdateSuccessfully'));
                        this.guardAdd.addBtnState = 0;
                        this.guardAdd.addGuardFrom = false;
                        //获取dtmf 配置列表
                        this.getEntranceGuardConfig(1);
                    }else{
                        this.guardAdd.addBtnState = 0;
                        this.$handerrcode(res.code);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.guardAdd.addBtnState = 0;
                })
            }
            
        },
        //Guard setting 点击编辑执行函数
        editlGuard(item){
            this.guardAdd.addGuardFrom = true;
            this.guardAdd.titleTex = this.$t('EditGuard');
            this.guardAdd.model = 1;
            this.guardAdd.entranceGuardId = item.entranceGuardId;
            this.guardAdd.guardName = item.guardName;
            this.guardAdd.apartmentId = item.userApartmentId;
            this.guardAdd.userHouseId = item.userHouseId;
        },
         //点击Guard删除图标执行
         openDelGuardFrom(item){
            this.guardDel.delGuardFrom = true;
            this.guardDel.entranceGuardId = item.entranceGuardId;
        },
        //删除Guard Setting delPanelsguardDel
        delPanelsguardDel(){
            //删除
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const delPanelsGuardObj = {}
            delPanelsGuardObj.entranceGuardId = this.guardDel.entranceGuardId;
            this.guardDel.delBtnState = 1;
            sitesPanelsApi.delSitePanelsGuardConfig(rgnObj,delPanelsGuardObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.guardDel.delGuardFrom = false
                    this.guardDel.delBtnState = 0;
                   //获取Guard 配置列表
                   this.getEntranceGuardConfig(1);
                }else{
                    this.$handerrcode(res.code);
                    this.guardDel.delBtnState = 0;
                }
                
            }).catch((err) => {
                console.log('err', err);
                this.guardDel.delBtnState = 0;
            })
        },
        /**
         * CCTV Settings
         */

        //获取设备CCTV配置
        getEntranceCCTVConfig(pageNum){
            let panelsCCTVObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "entranceDeviceId":this.updatePanels.id,
                "type":'cctv',
                "pageSize":10,
                "pageNum":pageNum,
            }
            sitesPanelsApi.getSitePanelsConfigList(panelsCCTVObj).then(res => {
                if(res.code === 0){
                    this.cctv.cctvList = [];
                    if(res.data === null || res.data.list.length === 0){
                        this.cctv.hasNocctv = true;
                    }else{
                        this.cctv.hasNocctv = false;
                        this.cctv.cctvList = res.data.list;
                    }
                    this.cctv.currentPage = res.data.pageNum;
                    this.cctv.totalcctvs = res.data.total;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //CCTV 分页操作
        handleSizeChangeCCTV(val) {
            // console.log(`每页 ${val} 条`);
        },
        //CCTV 分页操作
        handleCurrentChangeCCTV(val) {
            this.getEntranceCCTVConfig(val);
        },
        //点击添加 CCTV Add   按钮
        addCCTVSetting(){
            this.cctvAdd.cctvGuardFrom = true;
            this.cctvAdd.isAddCCTV = true;
            this.cctvAdd.titleTex = this.$t('AddCamera');
            this.cctvAdd.title = "";
            this.cctvAdd.serverUrl = "";
            this.cctvAdd.username = "";
            this.cctvAdd.password = "";
            this.cctvAdd.stream = "";
            this.cctvAdd.passwordImg = this.cctvAdd.hidePassword;
            this.cctvAdd.passwordFlage = "password";
        },

        //CCTV setting 点击编辑执行函数
        editlCCTV(item){
            this.cctvAdd.cctvGuardFrom = true;
            this.cctvAdd.titleTex = this.$t('EditCamera');
            this.cctvAdd.isAddCCTV = false;
            this.cctvAdd.entranceConfigId = item.entranceConfigId;
            this.cctvAdd.title = item.title;
            let cctvServerObj = JSON.parse(item.payload);
            this.cctvAdd.serverUrl = cctvServerObj.serverUrl;
            this.cctvAdd.username = cctvServerObj.username;
            this.cctvAdd.password = cctvServerObj.password;
            this.cctvAdd.stream = cctvServerObj.stream;
            this.cctvAdd.passwordImg = this.cctvAdd.hidePassword;
            this.cctvAdd.passwordFlage = "password";
        },
        //点击cctv显示隐藏密码执行函数
        changeCCTVPasswordShow(){
            if(this.cctvAdd.passwordImg === this.cctvAdd.showPassword){
                this.cctvAdd.passwordImg = this.cctvAdd.hidePassword;
                this.cctvAdd.passwordFlage = "password";
            }else{
                this.cctvAdd.passwordImg = this.cctvAdd.showPassword;
                this.cctvAdd.passwordFlage = "text";
            }
        },
        //新增/编辑 CCTV Settings 点击保存
        addOrEditCCTVSave(){
            let checkcctvName = this.$regularCheckLimit(this.cctvAdd.title,1,64,'NameCannotEmpty');
            if(!checkcctvName){
                return false
            }
            if((this.cctvAdd.serverUrl).trim() === ""){
                this.$message.info(this.$t('IPRTSPUrlEmpty'));
                return false
            }
            if((this.cctvAdd.username).trim() === ""){
                this.$message.info(this.$t('UsernameEmpty'));
                return false
            }else{
                if(this.cctvAdd.username.length > 64){
                    this.$message.info(this.$t("LimitUserName"));
                    return false
                }
            }
            if((this.cctvAdd.password).trim() != ""){
                if(this.cctvAdd.password.length > 64){
                    this.$message.info(this.$t("LimitPassword"));
                    return false
                }
            }
            if((this.cctvAdd.stream).trim() === ""){
                this.$message.info(this.$t('StreamEmpty'));
                return false
            }
            this.cctvAdd.cctvBtnState = 1;
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            }
            let addPanelcctvObj = {
                "type": "cctv",
                "entranceDeviceId": this.updatePanels.id,
                "title": this.cctvAdd.title,
            };
            addPanelcctvObj.payload = JSON.stringify({
                "name":this.cctvAdd.title,
                "serverUrl":this.cctvAdd.serverUrl,
                "username":this.cctvAdd.username,
                "password":this.cctvAdd.password,
                "stream":this.cctvAdd.stream,
            })
            if(this.cctvAdd.isAddCCTV){
                sitesPanelsApi.addSitePanelsConfig(rgnObj,addPanelcctvObj).then(res => {
                    if(res.code === 0){
                        this.$message.success(this.$t('AddSuccessful'));
                        this.cctvAdd.cctvBtnState = 0;
                        this.cctvAdd.cctvGuardFrom = false;
                        //cctv 配置列表
                        this.getEntranceCCTVConfig(1);
                    }else{
                        this.cctvAdd.cctvBtnState = 0;
                        this.$handerrcode(res.code);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.cctvAdd.cctvBtnState = 0;
                })
            }else{
                addPanelcctvObj.entranceConfigId = this.cctvAdd.entranceConfigId;
                sitesPanelsApi.updateSitePanelsConfig(rgnObj,addPanelcctvObj).then(res => {
                    if(res.code === 0){
                        this.$message.success(this.$t('UpdateSuccessfully'));
                        this.cctvAdd.cctvBtnState = 0;
                        this.cctvAdd.cctvGuardFrom = false;
                        //获取cctv 配置列表
                        this.getEntranceCCTVConfig(1);
                    }else{
                        this.cctvAdd.cctvBtnState = 0;
                        this.$handerrcode(res.code);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.cctvAdd.cctvBtnState = 0;
                })
            }
            
        },
        //点击CCTV删除图标执行
        openDelCCTVFrom(item){
            this.cctvDel.delCCTVFrom = true;
            this.cctvDel.entranceCCTVId = item.entranceConfigId;
        },
        //删除CCTV Setting
        delPanelsCCTV(){
            //删除
            let rgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            const delPanelsCCTVObj = {}
            delPanelsCCTVObj.entranceConfigId = this.cctvDel.entranceCCTVId;
            this.cctvDel.delBtnState = 1;
            sitesPanelsApi.delSitePanelsSipConfig(rgnObj,delPanelsCCTVObj).then(res => {
                if(res.code === 0){
                    this.$message.success(this.$t('DeleteSuccessfully'));
                    this.cctvDel.delCCTVFrom = false
                    this.cctvDel.delBtnState = 0;
                   //获取CCTV 配置列表
                   this.getEntranceCCTVConfig(1);
                }else{
                    this.$handerrcode(res.code);
                    this.cctvDel.delBtnState = 0;
                }
                
            }).catch((err) => {
                console.log('err', err);
                this.cctvDel.delBtnState = 0;
            })
        },
    },
    computed:{
        ...mapState(["selectCompany","selectSite"]),
    },
    watch:{
        'updatePanels.name'(){
            if(this.updatePanels.name != this.updatePanels.newName || this.updatePanels.apartmentId != this.updatePanels.newApartmentId){
                this.updatePanels.saveBtn = false;
            }else{
                this.updatePanels.saveBtn = true;
            }
            
        },
        'updatePanels.apartmentId'(){
            if(this.updatePanels.apartmentId != this.updatePanels.newApartmentId || this.updatePanels.name != this.updatePanels.newName){
                this.updatePanels.saveBtn = false;
            }else{
                this.updatePanels.saveBtn = true;
            }
            
        },
        'updatePanels.permissionList.user':{
            deep:true,
            handler(){
                if((this.updatePanels.permissionList.user).toString() != (this.updatePanels.oldPermissionList.user).toString() 
                || (this.updatePanels.permissionList.employee).toString() != (this.updatePanels.oldPermissionList.employee).toString()
                || (this.updatePanels.permissionList.others).toString() != (this.updatePanels.oldPermissionList.others).toString()){
                    this.updatePanels.savePermiBtn = false;
                }else{
                    this.updatePanels.savePermiBtn = true;
                }
            }
        },
        'updatePanels.permissionList.employee':{
            deep:true,
            handler(){
                if((this.updatePanels.permissionList.employee).toString() != (this.updatePanels.oldPermissionList.employee).toString() 
                || (this.updatePanels.permissionList.user).toString() != (this.updatePanels.oldPermissionList.user).toString()
                || (this.updatePanels.permissionList.others).toString() != (this.updatePanels.oldPermissionList.others).toString()){
                    this.updatePanels.savePermiBtn = false;
                }else{
                    this.updatePanels.savePermiBtn = true;
                }
            }
        },
        'updatePanels.permissionList.others':{
            deep:true,
            handler(){
                if((this.updatePanels.permissionList.others).toString() != (this.updatePanels.oldPermissionList.others).toString() 
                || (this.updatePanels.permissionList.user).toString() != (this.updatePanels.oldPermissionList.user).toString()
                || (this.updatePanels.permissionList.employee).toString() != (this.updatePanels.oldPermissionList.employee).toString()){
                    this.updatePanels.savePermiBtn = false;
                }else{
                    this.updatePanels.savePermiBtn = true;
                }
            }
        },
    }
}
</script>

<style scoped>
.panelsEditBox{
   box-sizing: border-box;
   padding: 15px 30px;
}
.controlBtnBox{
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 600px) {
    .controlBtnBox{
       justify-content: center;
    }
}
.el-button {
    height: 30px;
    /* width: 140px; */
    padding: 6px 20px;
    font-size: 15px;
    background: #ccc;
    color: #000;
}
.el-button:hover{
    background: #6498b5;
    color: #fff;
}
.el-button:active {
    background: #6498b5;
    color: #fff;
}
.el-button:focus {
    background: #6498b5;
    color: #fff;
  }

.selectBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
}
::v-deep .statusGreen{
    color: green;
}
::v-deep .statusOrange{
    color: orange;
}
::v-deep .statusRed{
    color: red;
}
::v-deep .statusBlue{
    color: blue;
}
.panelsItem{
    display: flex;
    align-items: baseline;
    margin-top: 1vw;
}
.panelsItem label{
   min-width: 150px;
}
@media screen and (max-width: 600px) {
    .panelsItem{
       justify-content: center;
    }
}
.editInput{
    width: 12vw;
    min-width: 170px;
}
@media screen and (max-width: 600px) {
    .editInput{
        width: 170px;
    }
}
::v-deep .editInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #333;
    background: #fff;
}
.panelsItem label{
    width: 135px;
    text-align: left;
}
.panelsItem input{
    width: 300px;
}
::v-deep .editInput .el-input__inner:hover {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #333;
    background: #fff;
}
::v-deep .el-input .el-input--suffix .is-focus{
    border: 1px solid #333 !important;
    border-radius: 4px !important;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
    border: 1px solid #333 !important;
    border-radius: 4px !important;
}
::v-deep .el-select .el-input .el-select__caret {
    color: #333;
    font-weight: bold;
}
::v-deep .el-input .el-input--suffix{
    border: 1px solid #333 !important;
    border-radius: 4px;
}
::v-deep .el-select .el-input__inner:focus {
    border: 1px solid #333 !important;
    border-radius: 4px;
}

.editBlock{
    width: 12vw;
    min-width: 170px;
}
@media screen and (max-width: 600px) {
    .editBlock{
        width: 170px;
    }
}
::v-deep .editBlock.el-select .el-input .el-select__caret {
    color: #c0c4cc !important;
}
/* ::v-deep .el-select .el-input.is-disabled .el-input__inner {
    background: #fff;
} */
::v-deep .el-select .el-input.is-disabled .el-input__inner {
    background: #eee;
}
.bottomDiv{
    position: fixed;
    bottom: 38px; 
    width:100%
}
.savetBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
    width: 220px;
    height: 36px;
    /* position: fixed;
    bottom: 10vw; */
}
/* Panels */

.panelsContent{
    margin-top: 20px;
}
/* .panelspermission{
    width: 30vw;
} */
.permissionEditDiv{
    margin-top: 1vw;
    /* border-radius: 8px;
    padding: 0.5vw 1vw 2vw 1vw; */
    /* background: #E9F6FF; */

}
.permissionTex{
    text-align: left;
}
.permissionTex span{
    vertical-align: middle;
    margin-left: 3px;
}
.el-divider--vertical{
    width: 4px;
    margin: 0 !important;
    background-color: #6498b5;
}

.permissionItem{
    display: flex;
    padding-top: 1vw;
    align-items: center;
}
.permissionEditLeft{
    width: 10vw;
    color: #333;
    font-size: 16px;
    text-align: left;
    min-width: 150px;
}
.permissionRight{
    /* width: 25vw; */
    display: flex;
    justify-content: space-around;
    min-width: 250px;
}

.permissionRight img:hover{
    cursor: pointer;
}

.panelsBox{
    display: flex;
    flex-wrap: wrap;
}
.panelsBoxLeft{
    /* width: 30%; */
    width: 30vw;
    min-width: 350px;
}
@media screen and (max-width: 600px) {
    .panelsBoxLeft{
        width: 100%;
    }
}
.panelsBoxRight{
    /* width: 30%; */
    width: 30vw;
    margin-top: 1vw;
    margin-left: 5vw;
    min-width: 350px
}
@media screen and (max-width: 600px) {
    .panelsBoxRight{
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }
}
.panelsRightItem{
    display: flex;
    margin-top: 1vw;
}
@media screen and (max-width: 600px) {
    .panelsRightItem{
        display: flex;
        margin-top: 20px;
        justify-content: center;
    }
}
.panelsItemL{
    width: 10vw;
    min-width: 150px;
}
@media screen and (max-width: 600px) {
    .panelsItemL{
        text-align: left;
    }
}
.panelsItemR{
    margin-left: 2vw;
    width: 5vw;
    min-width: 200px;
}

/* 校验外部验证码 */
::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 635px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        width: 300px;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-form-item__label {
    font-size: 18px !important;
    color: #333;
}
.el-form-item {
    margin-top: 10px;
}
::v-deep .el-input {
    font-size: 16px;
    margin-top: 10px;
}
::v-deep .el-input__inner {
    height: 50px;
    line-height: 50px;
    width: 100%;
    border:none;
    background: #EFEFEF;
}
.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 60px;
}
.el-dialog__footer {
    padding: 10px 20px 35px;
}

::v-deep .el-pagination__jump {
    margin-top: -10px !important;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
::v-deep .el-button+.el-button{
    margin-left: 4px !important;
}

/* sip settings */
.noSipsettings{
    color: #ACACAC;
    margin-top: 10vw;
}
::v-deep .el-table{
    min-height: 510px !important;
}
.pageBox {
    /* position: fixed;
    bottom: 2vw; */
    margin-top: 20px;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed;
        bottom: 10px; */
        margin-top: 20px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}
.addSipDiv{
    width: 26%;
    display: flex;
    justify-content: flex-start;
    padding: 25px 20px;
    background-color: #F4F4F4;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #F4F4F4;
}
.addSipLeft {
    /* border-right: 1px solid #6380F5; */
    padding-right: 60px;
}
.addSipLeft h4{
    text-align: left;
    color: #6498b5;
}
.sipItem{
    margin-top: 10px;
    display: flex;
    align-items: baseline;
}
.sipItem label{
    width: 135px;
    padding-right: 15px;
    text-align: left;
    font-size: 14px;
}
.sipItem input{
    width: 300px;
}
.addSipRight{
    padding-left: 60px;
}
.addSipRight h4{
    text-align: left;
    color: #6498b5;
}
.addSipRight label{
    width: 80px;
    padding-right: 15px;
    text-align: left;
    font-size: 14px;
}
.deleteDesc{
    line-height: 1.5;
    font-size: 16px;
    word-break: keep-all;
}
::v-deep .addStar::after {
    content: "*";
    margin-left: 3px
}
/* Guard  */
/* 新增Guard 弹框样式 */
.addGuardBox{
    display: flex;
    justify-content: center;
}

.NameBox{
    display: flex;
    justify-content: space-between;
}
::v-deep .apartmentSel.el-select .el-input.is-focus .el-input__inner{
    border: none !important;
}
::v-deep .apartmentSel.el-select .el-input__inner:focus{
    border: none !important;
}

.addGuard{
    margin-top: 20px;
}
.addGuard button{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    border-color: #dcdfe6;
    color: #606266;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    height: 40px;
}
.addGuard .el-button--primary {
    margin-left: 60px !important;
    color: #fff;
    background-color: #6498b5;
    border-color: #6498b5;
}

</style>