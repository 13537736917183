<template>
    <div class="siteLicensesBox">
         <!--licenses支付弹框-->
         <el-dialog :title="$t('BuyLiences')" 
         :close-on-click-modal="false" 
         :close-on-press-escape="false" 
         :visible.sync="serviceLicenses.buyLicensesFrom" 
         center 
         @close="closePayDialog"
         :show-close="serviceLicenses.showClose">
            <!-- 等待页面显示 -->
            <div v-show="serviceLicenses.showWaitForm">
                <div class="waitmentBox">
                    <img :src="serviceLicenses.waitmentImg" alt="">
                </div>
                <div class="waitText">{{$t('RocessingPayment')}}</div>
                <div slot="footer" class="dialog-footer confirmPayStateBtn">
                    <el-button type="primary" :loading="serviceLicenses.handSuccessBtnState === 1" @click="payConfirm(1)">{{$t('PaySuccess')}}</el-button>
                    <el-button type="primary" :loading="serviceLicenses.handFailBtnState === 1" @click="payConfirm(2)">{{$t('PayFail')}}</el-button>
                </div>
            </div>
            <!-- 支付状态展示显示 -->
            <div v-show="serviceLicenses.showPayStatusForm">
                <div class="waitmentBox">
                    <img v-show="serviceLicenses.paysuccess" :src="serviceLicenses.successImg" alt="">
                    <img v-show="!serviceLicenses.paysuccess" :src="serviceLicenses.failImg" alt="">
                </div>
                <div class="waitText" v-show="serviceLicenses.paysuccess">{{$t('PaySuccess')}}</div>
                <div class="waitText" v-show="!serviceLicenses.paysuccess">{{$t('PayFail')}}</div>
                <div slot="footer" class="dialog-footer payStateBtn">
                    <el-button type="primary" :loading="serviceLicenses.handConfirmSuccessBtnState === 1" @click="payConfirmSuccess">{{$t('Confirm')}}</el-button>
                </div>
            </div>
            <!-- licenses购买弹框 -->
            <div class="buyLicensesBox" v-show="serviceLicenses.showPayForm">
                <div class="buyTopBox">
                    <el-image class="licensesImg" :src="serviceLicenses.licenseImg" fit="contain"></el-image>
                    <p>{{serviceLicenses.licenseName}}</p>
                    <p>{{displayCurrency(serviceLicenses.currencyCode)+serviceLicenses.amount+ " " + "/" + $t('Person')}}</p>
                </div>
                <div class="buyQuantity">{{$t('BuyQuantity')}} :</div>
                <div class="buyQuantityNum">
                    <el-button :class="{selectLicenseBtn:serviceLicenses.selLicenses === 10}" @click="selectLicenses(10)">10</el-button>
                    <el-button :class="{selectLicenseBtn:serviceLicenses.selLicenses === 20}" @click="selectLicenses(20)">20</el-button>
                    <el-button :class="{selectLicenseBtn:serviceLicenses.selLicenses === 50}" @click="selectLicenses(50)">50</el-button>
                    <el-input v-model="serviceLicenses.customQuantity" @input="handlecustomQuantity" class="customQuantity" :placeholder="$t('CustomQuantity')"></el-input>
                </div>
                <div class="payPlatfrom">
                    <div>{{$t('PayPlatfrom')}} :</div>
                    <div class="paypalImg" v-show="serviceLicenses.payplatformPaypal"  style="margin-right: 30px;margin-right: 60px;">
                        <el-radio v-model="serviceLicenses.payPlatform" label="paypal" style="vertical-align: middle;">
                            <el-image :src="serviceLicenses.paypalImg" fit="contain" style="vertical-align: middle;margin-left: 5px;"></el-image>
                        </el-radio>
                    </div>
                    <div class="paypalImg" v-show="serviceLicenses.payplatformStripe">
                        <el-radio v-model="serviceLicenses.payPlatform" label="stripe" style="vertical-align: middle;">
                            <el-image :src="serviceLicenses.stripeImg" fit="contain" style="vertical-align: middle;margin-left: 5px;height: 35px;"></el-image>
                        </el-radio>
                    </div>
                </div>
                <div class="payAmount">
                    <div class="payAmountLeft">
                        <div>{{$t('PayAmount')}} :</div>
                        <div class="amountPay">{{serviceLicenses.totalAmountPayTex}}</div>
                    </div>
                    <div class="payLicensesBtn">
                        <el-button class="" :loading="serviceLicenses.payBtnState === 1" @click="paySiteLicenses">{{$t('Pay')}}</el-button>
                    </div>
                </div>
               
            </div>
        </el-dialog>
      <div class="controlBtnBox">
        <el-button :class="{selectBtn:selectBtnIndex===1}" @click="changeEditType(1)">{{$t('ServiceLicenses')}}</el-button>
        <el-button :class="{selectBtn:selectBtnIndex===2}" @click="changeEditType(2)">{{$t('PurchaseRecord')}}</el-button>
      </div>
      <!-- Service Licenses -->
      <div class="serviceLicensesBox" v-show="selectBtnIndex === 1">
        <div class="noLicenses" v-show="serviceLicenses.hasNoLicenses">{{$t('NoLicense')}}</div>
        <div class="licensesItem" v-show="!serviceLicenses.hasNoLicenses">
            <div class="licesesbox" 
            v-for="item in serviceLicenses.licensesList"
            :key="item.id"
            >
            <div class="topImg">
                <el-image class="licensesImg" :src="serviceLicenses.licenseImg" fit="contain"></el-image>
                <div style="width:6px;"></div>
                <p>{{item.name}}</p>
            </div>
            <div class="licensesNum">{{displayCurrency(item.currencyCode)+ item.amount}} <span>{{"/" + $t('Person')}} </span></div>
            <div>
                <el-button class="licensesBtn" @click="openBuySiteLicensesFrom(item)" type="primary">{{$t('BuyNow')}}</el-button>
            </div>
                
            </div>
        </div>
      </div>
      <!-- Purchase Records -->
      <div class="activationRecordsBox" v-show="selectBtnIndex === 2">
        <div class="serachBox">
                <div class="serachLeft">
                    <div>
                        <el-input v-model="purchaseRecords.orderNo" class="serachInput" :placeholder="$t('OrderNo')"></el-input>
                    </div>
                    
                    <div>
                        <el-select v-model="purchaseRecords.orderStatus" class="serachSel" :placeholder="$t('OrderStatus')" clearable>
                            <el-option
                            v-for="item in purchaseRecords.orderStatusList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="dataBox">
                        <div class="dataBoxDiv">
                            <el-date-picker
                            v-model="purchaseRecords.yearMonth"
                            type="month"
                            format="yyyy-MM"
                            value-format="yyyy-MM"
                            :placeholder="$t('Date')">
                            </el-date-picker>
                        </div>
                    </div>
                    <el-button type="primary" class="serachBtn"  @click="serachSiteLicense">{{$t('Serach')}}</el-button>
                </div>
            </div>
        <div class="noActivaRecord" v-show="purchaseRecords.hasNoRecords">{{$t('NoRecord')}}</div>
            <template>
                <el-table
                v-show="!purchaseRecords.hasNoRecords"
                :data="purchaseRecords.purchaseRecordList"
                style="width: 100%;"
                :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
                >
                <el-table-column
                    prop="orderNo"
                    :label="$t('OrderNo')"
                    width="300"
                    >
                    <template slot-scope="scope">
                        {{ $handFieldNull(scope.row.orderNo)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderName"
                    :label="$t('OrderName')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ $handFieldNull(scope.row.orderName)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="amount"
                    :label="$t('Amount')"
                    width="100"
                    >
                    <template slot-scope="scope">
                        {{ displayCurrency(scope.row.currencyCode) + scope.row.amount}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderStatus"
                    :label="$t('OrderStatus')"
                    width="150"
                    >
                    <template slot-scope="scope">
                        {{ langPayOrder(scope.row.orderStatus)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="userNickname"
                    :label="$t('AdminName')"
                    width="150"
                    >
                    <template slot-scope="scope">
                        {{ scope.row.userNickname}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderTime"
                    :label="$t('OrderTime')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ utctolocal(scope.row.orderTime)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="payTime"
                    :label="$t('PayTime')"
                    width="200"
                    >
                    <template slot-scope="scope">
                        {{ $handFieldNull(utctolocal(scope.row.payTime))}}
                    </template>
                </el-table-column>

                <el-table-column>

                </el-table-column>
            
                </el-table>
            </template>
            <div class="pageBox">
                <el-pagination
                    v-show="!purchaseRecords.hasNoRecords"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="purchaseRecords.currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="purchaseRecords.totalLicensesite"
                >
                </el-pagination>
            </div>
      </div>
      

    </div>
  
</template>

<script>
import {nanoid} from 'nanoid'
import { debounce } from 'lodash'
import { mapState, mapMutations } from 'vuex';
import paymentsApi from '@/api/payments/index.js'
import licenseImg from '@/assets/images/license.png'
import paypalImg from '@/assets/images/paypal.png'
import stripeImg from '@/assets/images/stripe.png'
import waitmentImg from '@/assets/images/test_driver_loading.gif'
import successImg from '@/assets/images/success.png'
import failImg from '@/assets/images/fail.png'
export default {
    name:"Payments",
    data(){
        return{
            selectBtnIndex:1,
            serviceLicenses:{
                hasNoLicenses:false,
                showPayStatusForm:false,
                showWaitForm:false,
                waitmentImg,
                paysuccess:true,
                successImg,
                failImg,
                licenseImg,
                payPlatform:"",
                payplatformPaypal:false,
                payplatformStripe:false,
                paypalImg,
                stripeImg,
                licenseId:"",
                licensesList:[],
                showClose:true,
                buyLicensesFrom:false,
                buyBtnState:0,
                id:"",
                goodsId:"",
                licenseName:"",
                selLicenses:10,
                quantity:"",
                serverDuration:"",
                serverDurationUnit:"",
                customQuantity:"",
                amount:"",
                currencyCode:"",
                totalAmountPay:"",
                totalAmountPayTex:"",
                orderNo:"",
                // payPlatform:"paypal",
                link:"",
                payConfigId:"",//z支付配置id，查询订单支付状态时使用
                timer:null, //定时器
                payBtnState:0,
                handSuccessBtnState:0,
                handFailBtnState:0,
                handConfirmSuccessBtnState:0,

            },
            purchaseRecords:{
                orderNo:"",
                orderStatus:"",
                orderStatusList:[
                    { id: '1', name: this.$t('Pending') },
                    { id: '9', name: this.$t('Completed') },
                    { id: '0', name: this.$t('Expired') }
                ],
                yearMonth:"",
                hasNoRecords:false,
                purchaseRecordList:[],
                currentPage:1,
                totalLicensesite:1,
            },
           
        }
    },
    mounted(){
        // this.$store.commit("CHANGEMODEL","company");
        let siteMangeBread = [
            {
                id:nanoid(),
                path:'/main/companydashboard',
                name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
            },
            {
                id:nanoid(),
                path:'/main/sitedashboard',
                name:this.selectSite.siteName
            },
            {
                id:nanoid(),
                path:'/main/payments',
                name:this.$t('Payments')
            },
           
        ];
        this.$generateBreadcrumb(siteMangeBread);
        //获取可用支付平台
        this.getPaymentPlatformList();
        //获取purchase licenses列表
        this.getPurchaseLicensesList();
    },
    methods:{
        ...mapMutations(["OPENINEDITPAGE"]),
        //点击功能切换按钮
        changeEditType(type){
            this.selectBtnIndex = type;
            if(type === 1){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/payments',
                        name:this.$t('Payments')
                    },
                    {
                        id:nanoid(),
                        path:'/main/payments',
                        name:this.$t('Payments')
                    }
                ];
                //this.$generateBreadcrumb(siteMangeBread);  
            }else if(type === 2){
                let siteMangeBread = [
                    {
                        id:nanoid(),
                        path:'/main/companydashboard',
                        name:this.selectCompany === "createCompany"? "":this.selectCompany.companyName,
                    },
                    {
                        id:nanoid(),
                        path:'/main/sitedashboard',
                        name:this.selectSite.siteName
                    },
                    {
                        id:nanoid(),
                        path:'/main/payments',
                        name:this.$t('Payments')
                    },
                    {
                        id:nanoid(),
                        path:'/main/payments',
                        name:this.$t('PurchaseRecord')
                    }
                ];
                //this.$generateBreadcrumb(siteMangeBread); 
                this.purchaseRecords.orderNo = "";
                this.purchaseRecords.yearMonth = "";
                this.purchaseRecords.orderStatus = "";
                let serachLicensesObj = {
                    "pageNum":1,
                };
                this.serachSiteLicense(serachLicensesObj);      
               
            }
        },
        //日期字符串转换函数
        stringDate(val,str) {
            if(val>1){
                if (str == "Y" || str == "y") {
                    return this.$t('Years')
                } else if (str == "M" || str == "m") {
                    return this.$t('Months')
                } else if (str == "D" || str == "d") {
                    return this.$t('Days')
                }
            }else{
                if (str == "Y" || str == "y") {
                    return this.$t('Year')
                } else if (str == "M" || str == "m") {
                    return this.$t('Month')
                } else if (str == "D" || str == "d") {
                    return this.$t('Day')
                }
            }
        },
         //显示币种公共方法
        displayCurrency(type) {
            var str = "";
            switch (type) {
                case "USD":
                    str = "$"
                    break;
                case "CNY":
                    str = "¥"
                    break;
                case "IRR":
                    str = "IRR"
                    break;
                default:
                    str = "$"
            }
            return str
        },
         //订单状态翻译函数
        langPayOrder(statue) {
            let type = "";
            if (statue == 1) {
                type = this.$t('Pending');
            }else if (statue == 9) {
                type = this.$t('Completed');
            } else if (statue == 0) {
                type = this.$t('Expired');
            }
            return type
        },
        //site Purchase 支付平台获取
        getPaymentPlatformList(){
            let paymentPlatformObj = {
                "rgn":this.selectCompany.companyRegion,
                "oemGroupId":webConfig.oemGroupId,
                "currencyCode":currencyCode.code,
            }
            paymentsApi.getPaymentPlatformList(paymentPlatformObj).then(res => {
                if(res.code === 0){
                    if(res.data.length === 0){
                        //无可用支付平台
                        console.log("No payment platform");
                    }else{
                        if(res.data.indexOf('paypal') > -1){
                            this.serviceLicenses.payplatformPaypal = true;
                        }else{
                            this.serviceLicenses.payplatformPaypal = false;
                        }
                        if(res.data.indexOf('stripe') > -1){
                            this.serviceLicenses.payplatformStripe = true;
                        }else{
                            this.serviceLicenses.payplatformStripe = false;
                        }
                    }
                    
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
            })
        },
        //site Purchase licenses列表获取
        getPurchaseLicensesList(){
            let licensesObj = {
                "rgn":this.selectCompany.companyRegion,
                "oemGroupId":webConfig.oemGroupId,
                "currencyCode":currencyCode.code,
            }
            this.serviceLicenses.handConfirmSuccessBtnState = 1;
            paymentsApi.getPurchaseLicensesList(licensesObj).then(res => {
                if(res.code === 0){
                    this.serviceLicenses.handConfirmSuccessBtnState = 0;
                    if(res.data.length === 0){
                        this.serviceLicenses.hasNoLicenses = true;
                    }else{
                        this.serviceLicenses.hasNoLicenses = false;
                        this.serviceLicenses.licensesList = [];
                        for(let i=0;i<res.data.length;i++){
                            this.serviceLicenses.licensesList.push(res.data[i]);
                        }
                    }
                    
                }else{
                    this.serviceLicenses.handConfirmSuccessBtnState = 0;
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err);
                this.serviceLicenses.handConfirmSuccessBtnState = 0;
            })
        },
        //点击立即购买执行函数
        openBuySiteLicensesFrom(item){
            this.serviceLicenses.buyLicensesFrom = true;
            this.serviceLicenses.showPayForm = true;
            this.serviceLicenses.showWaitForm = false;
            this.serviceLicenses.showPayStatusForm = false;
            this.serviceLicenses.id = item.id;
            this.serviceLicenses.goodsId = item.id;
            this.serviceLicenses.amount = item.amount;
            this.serviceLicenses.licenseName = item.name;
            this.serviceLicenses.selLicenses = 10;
            this.serviceLicenses.quantity = this.serviceLicenses.selLicenses;
            this.serviceLicenses.customQuantity = "";
            this.serviceLicenses.currencyCode = item.currencyCode;
            //默认10个调用计算总价的接口
            this.serviceLicenses.totalAmountPayTex = "";
            this.getSiteLicensesPreOrder(10);
            this.serviceLicenses.payPlatform = "";
        },
       
        //商品预下单函数
        getSiteLicensesPreOrder(num){
            //清空之前的价格
            this.serviceLicenses.totalAmountPayTex = "";
            let licensesRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let licensesPreOrderObj = {
                "goodsId": this.serviceLicenses.goodsId,
                "quantity": num,
                "terminalParams": ""
            }
            paymentsApi.licensesPreOrder(licensesRgnObj,licensesPreOrderObj).then(res => {
                if(res.code === 0){
                    //获取总价
                    this.serviceLicenses.totalAmountPay = res.data.totalAmountPay;
                    this.serviceLicenses.totalAmountPayTex = this.displayCurrency(this.serviceLicenses.currencyCode) + this.serviceLicenses.totalAmountPay;
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //选择licenses数量按钮执行函数
        selectLicenses(num){
            this.serviceLicenses.totalAmountPayTex = "";
            this.serviceLicenses.customQuantity = "";
            this.serviceLicenses.selLicenses = num;
            this.serviceLicenses.quantity = num;
            this.getSiteLicensesPreOrder(num);
        },
        //自定义licenses执行函数
        handlecustomQuantity:debounce(function() {
            this.serviceLicenses.totalAmountPayTex = "";
            //去除按钮选中样式
            this.serviceLicenses.selLicenses = "";
            let reg = /^([1-9][0-9]{0,2})$/;
            if(!reg.test(this.serviceLicenses.customQuantity)){
                this.$message.info(this.$t('licensesNumLimit'));
                this.serviceLicenses.customQuantity = "";
                return false
            }
            this.serviceLicenses.quantity = this.serviceLicenses.customQuantity;
            this.getSiteLicensesPreOrder(this.serviceLicenses.customQuantity);
        }, 500),
        //Buy Licenses弹框点击pay执行函数
        paySiteLicenses(){
            if(this.serviceLicenses.totalAmountPayTex === ""){
                this.$message.info(this.$t('SelectLicensePurchase'));
                return false
            }
            if(this.serviceLicenses.payPlatform === ""){
                this.$message.info(this.$t('SelectPayPlatform'));
                return false
            }
            this.serviceLicenses.payBtnState = 1;
            let licensesRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let licensesOrderObj = {
                "goodsId": this.serviceLicenses.goodsId,
                "quantity": this.serviceLicenses.quantity,
                "companyId":this.selectCompany.id,
                "siteId":this.selectSite.id,
                "terminalParams": ""
            }
            paymentsApi.licensesOrder(licensesRgnObj,licensesOrderObj).then(res => {
                if(res.code === 0){
                    //订单号
                    this.serviceLicenses.orderNo = res.data;
                    //支付
                    this.getSiteLicensesOrderPay();
                }else{
                    this.$handerrcode(res.code);
                    this.serviceLicenses.payBtnState = 0;
                }
            }).catch((err) => {
                console.log(err);
                this.serviceLicenses.payBtnState = 0;
            })
        },

        //商品支付
        getSiteLicensesOrderPay(){
            let licensesRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let licensesPayObj = {
                "orderNo":this.serviceLicenses.orderNo,
                "payPlatform":this.serviceLicenses.payPlatform,
            }
            paymentsApi.licensesOrderPay(licensesRgnObj,licensesPayObj).then(res => {
                if(res.code === 0){
                    if (res.data.payPlatform === "free" && res.data.statusCode == 0) {
                        this.serviceLicenses.buyLicensesFrom = true;
                        this.serviceLicenses.showWaitForm = false;
                        this.serviceLicenses.showPayForm = false;
                        this.serviceLicenses.showPayStatusForm = true;
                        this.serviceLicenses.paysuccess = true;
                        //获取purchase licenses列表
                        this.getPurchaseLicensesList();   
                        return false
                    }
                    //获取跳转连接
                    this.serviceLicenses.link = res.data.link;
                    this.serviceLicenses.payConfigId = res.data.payConfigId;
                    this.serviceLicenses.buyLicensesFrom = true;
                    this.serviceLicenses.showWaitForm = true;
                    this.serviceLicenses.showPayForm = false;
                    this.serviceLicenses.showPayStatusForm = false;
                    let openWindFrom = window.open(res.data.link,'','height=700,width=600,top=30,left=60,toolbar=no,menubar=yes,scrollbars=yes,resizable=yes,location=yes,status=no');
                    //调用定时器查询支付状态
                    this.serviceLicenses.timer = setInterval(() => {
                        this.getLicensesPayState();
                        if (openWindFrom.closed) {
                            //清除定时器
                            clearInterval(this.serviceLicenses.timer);
                            this.serviceLicenses.timer = null;
                            openWindFrom = null;
                        }
                    }, 1000);
                }else{
                    this.$handerrcode(res.code);
                }
                this.serviceLicenses.payBtnState = 0;
            }).catch((err) => {
                console.log(err);
                this.serviceLicenses.payBtnState = 0;
            })
        },

        //License订单状态查询
        getlicensesPayReflush(state){
            let licensesRgnObj = {
                "rgn":this.selectCompany.companyRegion,
            };
            let licensesPayReflushObj = {
                "orderNo":this.serviceLicenses.orderNo,
                "payPlatform":this.serviceLicenses.payPlatform,
                "payConfigId":this.serviceLicenses.payConfigId,
                "prepayid":"", //微信支付订单号,暂未用
            }
            if(state === 1){
                this.serviceLicenses.handSuccessBtnState = 1;
            }else{
                this.serviceLicenses.handFailBtnState = 1;
            }
            paymentsApi.licensesPayReflush(licensesRgnObj,licensesPayReflushObj).then(res => {
                if(res.code === 0){
                    if(state === 1){
                        this.serviceLicenses.handSuccessBtnState = 0;
                    }else{
                        this.serviceLicenses.handFailBtnState = 0;
                    }
                    this.serviceLicenses.buyLicensesFrom = false;
                }else{
                    // this.$handerrcode(res.code);
                    if(state === 1){
                        this.serviceLicenses.handSuccessBtnState = 0;
                    }else{
                        this.serviceLicenses.handFailBtnState = 0;
                    }
                    this.serviceLicenses.buyLicensesFrom = false;
                    this.$message.info(this.$t('PaymentFailure'));
                }
            }).catch((err) => {
                console.log(err);
                if(state === 1){
                    this.serviceLicenses.handSuccessBtnState = 0;
                }else{
                    this.serviceLicenses.handFailBtnState = 0;
                }
                this.serviceLicenses.buyLicensesFrom = false;
            })
        },
        //付款成功与否点击确认，支付成功，支付失败执行函数,刷新licenses列表
        payConfirm(state){
            if(this.serviceLicenses.timer != null){
                //清除定时器
                clearInterval(this.serviceLicenses.timer);
                this.serviceLicenses.timer = null;
            }
            //手动刷新
            this.getlicensesPayReflush(state);
            //获取purchase licenses列表
            this.getPurchaseLicensesList();
        },
        payConfirmSuccess(){
            //获取licenses列表
            this.getPurchaseLicensesList();
            this.serviceLicenses.buyLicensesFrom = false;
            if(this.serviceLicenses.timer != null){
                //清除定时器
                clearInterval(this.serviceLicenses.timer);
                this.serviceLicenses.timer = null;
            }
        },
        //点击关闭购买框执行函数
        closePayDialog(){
            if(this.serviceLicenses.timer != null){
                //清除定时器
                clearInterval(this.serviceLicenses.timer);
                this.serviceLicenses.timer = null;
            }
        },

         //获取订单状态
         getLicensesPayState(){
            let licensesStatePayObj = {
                "rgn":this.selectCompany.companyRegion,
                "orderNo":this.serviceLicenses.orderNo,
            }
            paymentsApi.getLicensesPayState(licensesStatePayObj).then(res => {
                if(res.code === 0){
                    if(res.data.paymentStatus === 1){
                        this.serviceLicenses.buyLicensesFrom = true;
                        this.serviceLicenses.showWaitForm = false;
                        this.serviceLicenses.showPayForm = false;
                        this.serviceLicenses.showPayStatusForm = true;
                        this.serviceLicenses.paysuccess = true;
                        if(this.serviceLicenses.timer != null){
                            //清除定时器
                            clearInterval(this.serviceLicenses.timer);
                            this.serviceLicenses.timer = null;
                        }
                    }else if(res.data.paymentStatus === 2){
                        this.serviceLicenses.buyLicensesFrom = true;
                        this.serviceLicenses.showWaitForm = false;
                        this.serviceLicenses.showPayForm = false;
                        this.serviceLicenses.showPayStatusForm = true;
                        this.serviceLicenses.paysuccess = false;
                        if(this.serviceLicenses.timer != null){
                            //清除定时器
                            clearInterval(this.serviceLicenses.timer);
                            this.serviceLicenses.timer = null;
                        }
                    }else if(res.data.paymentStatus === 0){

                    }else{
                        this.serviceLicenses.buyLicensesFrom = true;
                        this.serviceLicenses.showWaitForm = false;
                        this.serviceLicenses.showPayForm = false;
                        this.serviceLicenses.showPayStatusForm = true;
                        this.serviceLicenses.paysuccess = false;
                        if(this.serviceLicenses.timer != null){
                            //清除定时器
                            clearInterval(this.serviceLicenses.timer);
                            this.serviceLicenses.timer = null;
                        }
                    }
                    
                }else{
                    this.$handerrcode(res.code);
                    this.serviceLicenses.buyLicensesFrom = true;
                    this.serviceLicenses.showWaitForm = false;
                    this.serviceLicenses.showPayForm = false;
                    this.serviceLicenses.showPayStatusForm = true;
                    this.serviceLicenses.paysuccess = false;
                    if(this.serviceLicenses.timer != null){
                        //清除定时器
                        clearInterval(this.serviceLicenses.timer);
                        this.serviceLicenses.timer = null;
                    }
                }
            }).catch((err) => {
                console.log(err);
                this.serviceLicenses.buyLicensesFrom = true;
                this.serviceLicenses.showWaitForm = false;
                this.serviceLicenses.showPayForm = false;
                this.serviceLicenses.showPayStatusForm = true;
                this.serviceLicenses.paysuccess = false;
                if(this.serviceLicenses.timer != null){
                    //清除定时器
                    clearInterval(this.serviceLicenses.timer);
                    this.serviceLicenses.timer = null;
                }
            })
        },
        // Purchase Records
        //搜索licenses
        serachSiteLicense(serachLicensesObj){

            if((this.purchaseRecords.orderNo).trim() != ""){
                let checkResultFirstName = this.$regularCheckLimit(this.purchaseRecords.orderNo,1,64,'OrderNoLimit');
                if(!checkResultFirstName){
                    return false
                }
            }
            if(this.purchaseRecords.yearMonth === null){
                this.purchaseRecords.yearMonth = "";
            }
            let licensesRgnObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.selectSite.id,
                "orderNo":this.purchaseRecords.orderNo,
                "yearMonth":(this.purchaseRecords.yearMonth).trim() === "" ? "" :  this.purchaseRecords.yearMonth + '-01',
                "orderStatus":this.purchaseRecords.orderStatus,
                "companyId":this.selectCompany.id,
                "pageSize":10,
                "pageNum":serachLicensesObj.pageNum,
            };
            
            paymentsApi.getLicensesPurchaseRecord(licensesRgnObj).then(res => {
                if(res.code === 0){
                    if(res.data.list === null){
                        this.purchaseRecords.purchaseRecordList = [];
                        this.purchaseRecords.hasNoRecords = true;
                        return false
                    }
                    this.purchaseRecords.currentPage = res.data.pageNum;
                    this.purchaseRecords.totalLicensesite = res.data.total;
                    if(res.data.list.length === 0){
                        this.purchaseRecords.purchaseRecordList = [];
                        this.purchaseRecords.hasNoRecords = true;
                    }else{
                        this.purchaseRecords.hasNoRecords = false;
                        this.purchaseRecords.purchaseRecordList = [];
                        for(let i=0;i<res.data.list.length;i++){
                            this.purchaseRecords.purchaseRecordList.push(res.data.list[i]);
                        }
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            let serachLicensesObj = {
                "pageNum":val,
            };
            this.serachSiteLicense(serachLicensesObj);
        }
    },
    computed:{
        ...mapState(["selectCompany","selectSite"])
    },
    beforeDestroy () {
        if(this.serviceLicenses.timer != null){
            //清除定时器
            clearInterval(this.serviceLicenses.timer);
            this.serviceLicenses.timer = null;
        }
    },
    watch:{
       
        // 'updateBlocks.number'(){
        //     if(this.updateBlocks.number != this.updateBlocks.newNumber || this.updateBlocks.name != this.updateBlocks.newName){
        //         this.updateBlocks.saveBtn = false;
        //     }else{
        //         this.updateBlocks.saveBtn = true;
        //     }
            
        // },
    }
}
</script>

<style scoped>
.siteLicensesBox{
   box-sizing: border-box;
   padding: 15px 30px;
}
.controlBtnBox{
    display: flex;
}
.el-button {
    height: 30px;
    /* width: 140px; */
    padding: 6px 20px;
    font-size: 15px;
    background: #ccc;
    color: #000;
}
.el-button:hover{
    background: #6498b5;
    color: #fff;
}
.el-button:active {
    background: #6498b5;
    color: #fff;
}
.el-button:focus {
    background: #6498b5;
    color: #fff;
  }

.selectBtn{
    background: #6498b5;
    color: #fff;
    font-weight: bold;
}
/* Service Licenses */
.serviceLicensesBox{
    margin-top: 20px;
}
.noLicenses{
    color: #ACACAC;
    margin-top: 10vw;
}
.licensesItem{
    /* width: 98vw;
    max-width: 1200px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-flow: wrap;
    margin-left: 32px;
}
@media screen and (max-width: 700px) {
    .licensesItem{
        margin-left: 10px;
        justify-content: center;
    }
}
.licesesbox{
    background-color: #F4F9FD;
    width: 12vw;
    height: 12vw;
    min-width: 260px;
    min-height: 240px;
    margin: 16px;
    /* padding-bottom: 10px; */
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.topImg{
    display: flex;
    align-items: center;
}
.topImg p{
    color: #505256;
    font-weight: bold;
}
.licensesImg{
    width:28px;
    height: 28px;
}
.licensesNum{
    font-size: 50px;
    font-weight: bold;
    color: #505256;
}
.licensesNum span{
    font-size: 18px;
    font-weight: 100;
    color: #85878C;
}
.licensesTex{
    font-size: 14px;
    color: #6C6E73;
}
.licensesBtn{
    background: #6498b5;
    color: #fff;
    padding: 6px 35px;
}
.editInput{
    width: 12vw;
    min-width: 170px;
}
@media screen and (max-width: 600px) {
    .editInput{
        width: 170px;
    }
}
::v-deep .editInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: 1px solid #333;
    background: #fff;
}

/* Activation Records */
::v-deep .el-dialog {
    border-radius: 15px !important;
    width: 640px;
}
@media screen and (max-width: 600px) {
    ::v-deep .el-dialog {
        border-radius: 15px !important;
        min-width: 300px;
        overflow: auto;
    }
}
::v-deep .el-dialog__header {
    background-color: #6498b5 !important;
    border-radius: 15px 15px 0 0 !important;
}
::v-deep .el-dialog__title {
    color: #fff !important;
}
::v-deep .el-form-item__label {
    font-size: 18px !important;
    color: #333;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 24px;
    line-height: 0.6;
}
::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
.activationRecordsBox{
    margin-top: 20px;
}
/* 等待框样式 */
.waitmentBox{
    display: flex;
    justify-content: center;
}

.waitmentBox img{
    margin-top: 5vw;
    width: 100px;
}
.waitText{
    margin-top: 4vw;
    margin-bottom: 4vw;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}

.confirmPayStateBtn{
    display: flex;
    justify-content: center;
    padding-bottom: 1vw;
}
.confirmPayStateBtn button{
    background: #6498b5 !important;
    color: #fff !important;
    margin-left: 2vw;
    height: 36px;
    /* width: 140px; */
    font-size: 18px;
}

.payStateBtn{
    display: flex;
    justify-content: center;
    padding-bottom: 1vw;
}
.payStateBtn button{
    background: #6498b5 !important;
    color: #fff !important;
    height: 36px;
    width: 140px;
    font-size: 18px;
}

/* Licenses购买框样式 */
.buyLicensesBox{
    display: flex;
    flex-direction: column;
}
.payLicensesBtn button{
    background: #6498b5 !important;
    color: #fff !important;
    margin-right: 2.7vw;
    height: 36px;
    width: 140px;
    font-size: 18px;
}
.buyTopBox{
    display: flex;
    align-items: center;
    margin-bottom: 1vw;
}
.buyTopBox p{
    margin-left: 1vw;
    font-size: 18px;
    line-height: 28px;
}
.buyQuantity{
    display: flex;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1vw;
}
.buyQuantityNum{
    display: flex;
    margin-bottom: 2vw;
}
.buyQuantityNum button{
    background: #EFEFEF;
    color: #000;
    width: 80px;
    height: 40px;
    margin-right: 1.5vw;
    border: none;
}
.selectLicenseBtn{
    background: #6498b5 !important;
    color: #fff !important;
}
.customQuantity{
    width: 10vw;
}
::v-deep .customQuantity .el-input__inner{
    background: #EFEFEF !important;
    border: none;
}
.payPlatfrom{
    display: flex;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2vw;
    align-items: center;
}
.paypalImg{
    width: 120px;
    margin-left: 1.5vw;
}
.payAmount{
    display: flex;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1vw;
    align-items: center;
    justify-content: space-between;
}
.payAmountLeft{
    display: flex;
    align-items: center;
}
.amountPay{
    margin-left: 1.5vw;
    font-size: 30px;
    color: #6498b5;
}
.noActivaRecord{
    color: #ACACAC;
    margin-top: 10vw;
}

.serachBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1vw;
    margin-bottom: 2vw;
}
@media screen and (max-width: 600px) {
    .serachBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* padding: 30px 10px; */
    }
}
/* 搜索栏样式 */
::v-deep .serachInput{
    width: 12vw;
    min-width: 170px;
    margin-right: 1vw;
    border: 1px solid #979797;
    background: #fff !important;
    border-radius: 4px;

}
@media screen and (max-width: 600px) {
    .serachInput{
        width: 170px;
        border: 1px solid #979797;
        background: #fff !important;
        border-radius: 4px;
    }
}

::v-deep .dataBoxDiv{
    width: 12vw;
    min-width: 220px;
    margin-right: 1vw;
    border: 1px solid #979797;
    background: #fff !important;
    border-radius: 4px;

}
::v-deep .serachInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: none;
}
.dataBox{
    display: flex;
    align-items: center;
}
.dataBox div{
    margin-right: 10px;
    min-width: 220px;
}
::v-deep .dataBox .el-input__inner{
    border: none;
}
.serachSel{
    width: 12vw;
    min-width: 170px;
    margin-right: 24px;
    border: 1px solid #979797;
    background: #fff !important;
    border-radius: 4px;
}
@media screen and (max-width: 600px) {
    .serachSel{
        width: 170px;
        margin-top: 10px;
        margin-right: 24px;
        border: 1px solid #979797;
        background: #fff;
        border-radius: 4px;
    }
}

::v-deep .serachSel .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input__inner:focus {
    border: none;
}
.serachItem{
    min-width: 170px;
}
.serachBtn{
    background: #6498b5;
    margin-left: 14px;
    width: 90px;
    height: 40px;
    color: #fff;
}
@media screen and (max-width: 600px) {
    .serachBtn{
        margin-top: 10px;
        width: 170px;
    }
}
.serachLeft{
    margin-top: 0.5vw;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.pageBox {
    /* position: fixed;
    bottom: 2vw; */
    margin-top: 20px;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed;
        bottom: 10px; */
        margin-top: 20px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}
::v-deep .el-table{
    min-height: 510px !important;
}
::v-deep .el-pagination__editor.el-input .el-input__inner{
    background-color: #99B9CB;
    color: #fff;
}
.el-button+.el-button{
    margin-left: 4px;
}
</style>