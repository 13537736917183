<template>
    <div class="ComPurchRec">
        <div class="activationRecordsBox">
        <div class="serachBox">
            <div class="serachLeft">
                <div>
                    <el-select v-model="purchaseRecords.siteId" class="serachSel" :placeholder="$t('Sites')" clearable>
                        <el-option
                        v-for="item in purchaseRecords.siteList"
                        :key="item.id"
                        :label="item.siteName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <!-- <div>
                    <el-input v-model="purchaseRecords.adminName" class="serachInput" :placeholder="$t('AdminName')"></el-input>
                </div> -->
                <div class="dataBox">
                    <!-- <div>{{$t('StartTime')}}</div> -->
                    <div class="dataBoxDiv">
                        <el-date-picker
                        :placeholder="$t('StartTime')"
                        v-model="purchaseRecords.startTime"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="dateDivider">_</div>
                <div class="dataBox">
                    <!-- <div> - {{$t('EndTime')}}</div> -->
                    <div class="dataBoxDiv">
                        <el-date-picker
                        :placeholder="$t('EndTime')"
                        v-model="purchaseRecords.endTime"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div>
                    <el-button type="primary" class="serachBtn"  @click="serachComLicense">{{$t('Serach')}}</el-button>
                </div>
                
            </div>
        </div>
        <el-divider></el-divider>
        <div class="noActivaRecord" v-show="purchaseRecords.hasNoRecords">{{$t('NoPurchaseRecord')}}</div>
        <template>
            <el-table
            v-show="!purchaseRecords.hasNoRecords"
            :data="purchaseRecords.purchaseRecordList"
            style="width: 100%;"
            :header-cell-style="{background:'#EDEFFB',color:'#4D4D4F'}"
            >
            <el-table-column
                prop="siteName"
                :label="$t('Sites')"
                width="200"
                >
                <template slot-scope="scope">
                    {{ scope.row.siteName}}
                </template>
            </el-table-column>
            <el-table-column
                prop="orderName"
                :label="$t('LicenseName')"
                width="200"
                >
                <template slot-scope="scope">
                    {{ scope.row.orderName}}
                </template>
            </el-table-column>
            <el-table-column
                prop="quantity"
                :label="$t('Quantity')"
                width="150"
                >
                <template slot-scope="scope">
                    {{ scope.row.quantity}}
                </template>
            </el-table-column>
            <el-table-column
                prop="amount"
                :label="$t('Amount')"
                width="150"
                >
                <template slot-scope="scope">
                    {{ displayCurrency(scope.row.currencyCode) + scope.row.amount}}
                </template>
            </el-table-column>
            <el-table-column
                prop="userNickname"
                :label="$t('AdminName')"
                width="150"
                >
                <template slot-scope="scope">
                    {{ scope.row.userNickname}}
                </template>
            </el-table-column>
            
            <el-table-column
                prop="createDate"
                :label="$t('CreateDate')"
                width="200"
                >
                <template slot-scope="scope">
                    {{ utctolocal(scope.row.createDate)}}
                </template>
            </el-table-column>
            <el-table-column>

            </el-table-column>
        
            </el-table>
        </template>
        <div class="pageBox">
            <el-pagination
                v-show="!purchaseRecords.hasNoRecords"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="purchaseRecords.currentPage"
                :page-size="10"
                layout="prev, pager, next, jumper"
                :total="purchaseRecords.totalLicensesite"
            >
            </el-pagination>
        </div>
      </div>
    </div>
  
</template>

<script>
import {nanoid} from 'nanoid'
import { mapState } from 'vuex';
import purchaserecordApi from '@/api/purchaserecord/index.js'
export default {
    name:"PurchaseRecord",
    data(){
        return{
            purchaseRecords:{
                siteId:"",
                siteList:[],
                // adminName:"",
                startTime:"",
                endTime:"",
                hasNoRecords:true,
                purchaseRecordList:[],
                currentPage:1,
                totalLicensesite:1,
            },
        }
    },
    mounted(){
       this.getCompanySiteList();
       let serachLicensesObj = {
            "pageNum":1,
        };
        this.serachComLicense(serachLicensesObj);
    },
    methods:{
         //显示币种公共方法
         displayCurrency(type) {
            var str = "";
            switch (type) {
                case "USD":
                    str = "$"
                    break;
                case "CNY":
                    str = "¥"
                    break;
                case "IRR":
                    str = "IRR"
                    break;
                default:
                    str = "$"
            }
            return str
        },
        //获取公司下site列表
        getCompanySiteList(){
            let conpanyInfoObj = {
                "rgn":this.selectCompany.companyRegion,
                "companyId":this.selectCompany.id,
                "pageSize":-1,
                "pageNum":1
            }
            purchaserecordApi.getCompanySiteListNoPage(conpanyInfoObj).then(res => {
                if(res.code === 0){
                    // console.log(res.data.list == null)
                    if(res.data == null || res.data.length === 0){
                        this.purchaseRecords.siteList = [];
                    }else{
                        this.purchaseRecords.siteList = [];
                        res.data.list.map(item => {
                            this.purchaseRecords.siteList.push(item)
                        })
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //搜索公司licenses购买记录
        serachComLicense(serachLicensesObj){
            // if((this.purchaseRecords.adminName).trim() != ""){
            //     let checkResultFirstName = this.$regularCheckLimit(this.purchaseRecords.adminName,1,100,'AdminAccountLimit');
            //     if(!checkResultFirstName){
            //         return false
            //     }
            // }
           
            if(this.purchaseRecords.startTime === null){
                this.purchaseRecords.startTime = "";
            }
            if(this.purchaseRecords.endTime === null){
                this.purchaseRecords.endTime = "";
            }
            if((this.purchaseRecords.startTime).trim() != "" && (this.purchaseRecords.endTime).trim() === ""){
                this.$message.info(this.$t('EndTimeEmptye'));
                return false
            }
            if((this.purchaseRecords.startTime).trim() === "" && (this.purchaseRecords.endTime).trim() != ""){
                this.$message.info(this.$t('StartTimeEmptye'));
                return false
            }
            if(this.purchaseRecords.startTime > this.purchaseRecords.endTime){
                this.$message.info(this.$t('StartTimeLaterEndTime'));
                return false
            }
            let licensesRgnObj = {
                "rgn":this.selectCompany.companyRegion,
                "siteId":this.purchaseRecords.siteId,
                "startTime":this.purchaseRecords.startTime,
                "endTime":this.purchaseRecords.endTime,
                // "adminName":this.purchaseRecords.adminName,
                "companyId":this.selectCompany.id,
                "pageSize":10,
                "pageNum":serachLicensesObj.pageNum,
            };
            
            purchaserecordApi.getLicensesPurchaseRecord(licensesRgnObj).then(res => {
                if(res.code === 0){
                    if(res.data.list === null || res.data.list.length === 0){
                        this.purchaseRecords.hasNoRecords = true;
                        this.purchaseRecords.purchaseRecordList = [];
                    }else{
                        this.purchaseRecords.hasNoRecords = false;
                        this.purchaseRecords.totalLicensesite = res.data.total;
                        this.purchaseRecords.currentPage = res.data.pageNum;
                        this.purchaseRecords.purchaseRecordList = [];
                        for(let i=0;i<res.data.list.length;i++){
                            this.purchaseRecords.purchaseRecordList.push(res.data.list[i]);
                        }
                    }
                }else{
                    this.$handerrcode(res.code);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            let serachLicensesObj = {
                "pageNum":val,
            };
            this.serachComLicense(serachLicensesObj);
        }
    },
    computed:{
        ...mapState(["selectCompany","companyList"])
    },
    watch:{
        "selectCompany.id"(){
            this.getCompanySiteList();
            let serachLicensesObj = {
                "pageNum":1,
            };
            this.serachComLicense(serachLicensesObj);
        }
    }
}
</script>

<style scoped>
.ComPurchRec{
    padding: 20px 30px;
}
.activationRecordsBox{
    margin: 1vw;
}
.serachBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1vw;
    margin-bottom: 2vw;
}
@media screen and (max-width: 800px) {
    .serachBox{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        /* padding: 30px 10px; */
    }
}
/* 搜索栏样式 */
::v-deep .serachInput{
    width: 12vw;
    min-width: 170px;
    margin-right: 1vw;
    border: 1px solid #979797;
    background: #fff !important;
    border-radius: 4px;

}
@media screen and (max-width: 600px) {
    .serachInput{
        width: 170px;
        border: 1px solid #979797;
        background: #fff !important;
        border-radius: 4px;
    }
}
::v-deep .serachInput .el-input__inner {
    height: 39px!important;
    line-height: 39px!important;
    border: none;
}
.serachSel{
    width: 12vw;
    min-width: 170px;
    margin-right: 1vw;
    border: 1px solid #979797;
    background: #fff !important;
    border-radius: 4px;
}
@media screen and (max-width: 600px) {
    .serachSel{
        width: 170px;
        margin-top: 10px;
        border: 1px solid #979797;
        background: #fff;
        border-radius: 4px;
    }
}
.dataBox{
    display: flex;
    align-items: center;
}
/* .dataBox div{
    margin-right: 10px;
} */

.dateDivider{
    line-height: 26px;
    padding: 0 6px;
}
/* ::v-deep .dataBoxDiv{
    width: 12vw;
    min-width: 220px;
    margin-right: 1vw;
    border: 1px solid #979797;
    background: #fff !important;
    border-radius: 4px;

} */
::v-deep .dataBoxDiv .el-input__inner{
    border: 1px solid #979797;
    padding: 20px 30px;
}
.el-divider--horizontal{
    height: 2px;
}
::v-deep .serachSel .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
::v-deep .el-select .el-input__inner:focus {
    border: none;
}
.serachItem{
    min-width: 170px;
}
.serachBtn{
    background: #6498B5;
    margin-left: 24px;
    width: 90px;
    height: 40px;
    color: #fff;
}
@media screen and (max-width: 600px) {
    .serachBtn{
        margin-left: 24px;
        margin-top: 10px;
        width: 170px;
    }
}
.serachLeft{
    margin-top: 0.5vw;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
    .serachLeft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }
    .serachLeft div{
        margin-top: 10px;
    }
}

.pageBox {
    /* position: fixed; */
    margin-top: 20px;
    bottom: 2vw;
    width: 96%;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .pageBox {
        /* position: fixed; */
        margin-top: 20px;
        bottom: 10px;
        justify-content: center;
    }
    .el-pagination {
        width: 100vw;
        overflow: auto;
    }
}
::v-deep .el-pagination__editor.el-input .el-input__inner{
    background-color: #99B9CB;
    color: #fff;
}
::v-deep .el-table{
    min-height: 510px !important;
}
</style>